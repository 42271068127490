<template>
  <div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="多平台直播" name="first">
          <div class="Platformlist">
            <div class="Platform">
              <div class="font">
                <div class="titles">多平台直播</div>
                <div>开启平台直播，最多添加十个</div>
              </div>
              <div class="swit">
                <el-switch
                  v-model="value"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div>
            <div class="pushlist">
              <div class="titlelist">
                <div>
                  <h4>10个推流平台还不够？</h4>
                  <h3>一键申请，更多的主流平台随你挑，轻松帮你上首页</h3>
                </div>
                <div>
                  <el-button type="primary" round>我要申请直播分发</el-button>
                </div>
                <div>电话申请：186 2002 7314 | 请提前至少3个工作日申请</div>
              </div>
              <div>
                <img src="../images/6.png" alt="" />
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="视频录播" name="second">
          <div class="configuration">
            <div>
              <h3><i class="iconfont icon-shuxian listlone"></i>上传视频</h3>
              <h6>
                适合上传提前录制好的视频，话题开始时间过后可进行观看，无需再次推流直播。您也可通过此方法替换话题回放
              </h6>
            </div>
            <div>
              <video
                controls
                class="videolist"
                src="https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218025702PSiVKDB5ap.mp4"
              ></video>
            </div>
            <div>
              <el-button plain>上传视频</el-button>
              <el-button plain>素材库选择</el-button>
              <el-button plain>从小视频选择</el-button>
              <h6>
                请上传小于8G的视频，只支持MP4(H.264/AVC)格式，如视频未能正常播放，可尝试自行转码后上传
                视频转码教程
              </h6>
            </div>
          </div>
          <div class="buttomlist">
            <el-button type="primary" round>主要按钮</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="拉流直播" name="third">
          <div>
            <div>
              <i class="iconfont icon-shuxian listlone"></i>
              <span class="titlelistfrom">拉流直播</span>
            </div>
            <div class="flexlistfrom">
              <div class="setlist">
                <h5>可将其他的拉流地址或视频流内容推流到微赞直播</h5>
                <h5>拉流直播过程请勿使用话题推流地址推流，避免串流情况</h5>
              </div>
              <div>
                <el-switch
                  v-model="value"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div>
          </div>
          <!-- 视频，拉流 -->
          <div class="currentlist">
            <!-- 单选 -->
            <div>
              <el-radio v-model="radio" label="1">备选项</el-radio>
              <el-radio v-model="radio" label="2">备选项</el-radio>
            </div>
            <!-- 按钮 -->
            <div>
              <el-button plain>上传视频</el-button>
              <el-button plain>从素材库选择</el-button>
              <el-button plain>从小视频选择</el-button>
            </div>
            <div>
              <h5 class="spantitle">
                视频大小限制为8G，只支持MP4(H.264/AVC)视频格式。如果视频未能正常播放，可尝试自行转码后上传
                视频转码教程
              </h5>
            </div>
          </div>
          <!-- 开播时间 -->
          <div class="broadcastlist">
            <span class="titlelistfrom">拉流直播</span>
            <div>
              <el-radio v-model="radio" label="1">备选项</el-radio>
              <el-radio v-model="radio" label="2">备选项</el-radio>
            </div>
          </div>
          <!-- 结束时间 -->
          <div class="broadcastlist">
            <span class="titlelistfrom">结束时间</span>
            <div class="pushtimelist">
              推流至视频时长结束后，直播状态自动结束
            </div>
          </div>
          <!-- 清晰度设置 -->
          <div class="broadcastlist">
            <span class="titlelistfrom">拉流直播</span>
            <div>
              <el-radio v-model="radio" label="1">备选项</el-radio>
              <el-radio v-model="radio" label="2">备选项</el-radio>
            </div>
            <div class="pushtimelist">
              推流至视频时长结束后，直播状态自动结束
            </div>
            <div class="borderbottom"></div>
          </div>
          <div class="bottomlist">
            <el-button type="primary" disabled plain>直播结束</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
// import VueAliplayerv2 from 'vue-aliplayer-v2'
export default {
  data () {
    return {
      activeName: 'first',
      value: false,
      radio: '1'
    }
  },
  methods: {

  },

}
</script>
<style>
.Platform {
  display: flex;
  border-bottom: 0.3px solid #ccc;
  padding: 50px 0 60px 0;
  margin: 0 20px;
}
.font div {
  font-size: 14px;
  color: #ccc;
}
.font .titles {
  font-weight: 900;
  margin-bottom: 5px;
  font-size: 16px;
  color: black;
}
.swit {
  margin: 15px 0 0 35px;
}
.pushlist {
  width: 80%;
  margin: 100px auto;
  background-color: #f0f6ff;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.titlelist div {
  margin: 15px 0 15px 10px;
}
.titlelist div h3 {
  margin-top: 10px;
}
.videolist {
  width: 400px;
  height: 200px;
  background-color: #0000;
}
/* 配置管理 */
.configuration {
  margin: 5px 10px;
  border-bottom: 1px solid #edeff2;
}
.configuration div {
  margin: 15px 0;
}
.configuration div h3,
h6 {
  /* color: red; */
  margin: 10px 10px;
}
.configuration div h6 {
  /* color: #919499; */
  font-weight: 300;
}
.buttomlist {
  width: 50%;
  margin: 40px auto;
  text-align: center;
}
/* 拉流直播 */
.listlone {
  color: blue;
  font-size: 20px;
  font-weight: 900;
}
.flexlistfrom {
  display: flex;
}
.titlelistfrom {
  font-size: 18px;
  font-weight: 900;
}
.flexlistfrom {
  margin: 20px 0;
}
.setlist {
  margin: 0 40px 0 20px;
}
.setlist h5 {
  font-weight: 400;
  color: #919499;
}
.spantitle {
  font-weight: 400;
  color: #919499;
}
.currentlist {
  margin: 0 40px 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #edeff2;
}
.currentlist div {
  margin: 15px 0;
}
/* 开播时间 */
.broadcastlist {
  margin: 20px 40px 0 20px;
}
.broadcastlist div {
  margin: 10px 0;
}
.pushtimelist {
  background-color: #f0f6ff;
  padding: 20px;
  width: 362px;
  font-size: 14px;
}
.borderbottom {
  border-bottom: 1px solid #edeff2;
}
.bottomlist {
  margin: 20px 0;
  text-align: center;
  /* margin: 10px auto; */
}
</style>
