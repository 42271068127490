<template>
  <div class="selecterzong">
    <div class="selected">
      <div class="seletitle">设置精选</div>
      <!-- 选择内容查看 -->
      <div class="xuanzesele">
        <el-radio-group v-model="radio2" class="weblist">
          <el-radio label="精选推荐" border>精选推荐</el-radio>
          <el-radio label="医学会议" border>医学会议</el-radio>
          <el-radio label="医学直播" border>医学直播</el-radio>
          <el-radio label="医学视频" border>医学视频</el-radio>
        </el-radio-group>
      </div>
      <!-- 进入编辑 -->
      <div class="contentsele">
        <div class="namecontentsele">{{radio2}}(最多添加8个)</div>
        <div class="listcontentedit" @click="edmitselected">编辑</div>
      </div>
      <!-- 具体的内容 -->
      <div class="contentseleted" v-if="radio2=='精选推荐'">
        <div class="contentweblist"  v-for="(item,index) in selectedwebcontent" :key="index">
          <div class="imagebacklist">
            <img :src="item.poster_url" alt="">
          </div>
          <div class="contenttitlelist">
            <div class="introduce">
              {{ item.title }}
            </div>
            <div class="titletime">
                <div class="location">地点</div>
                <div class="time">{{ item.edittime }}</div>
              </div>
              <div class="browse">
                <div class="backcontent"></div>
                <div class="viewtitle">{{ item.view_count }}</div>
              </div>
          </div>
          <div class="weblistcontentsele">
            <div @click="toplistbao(index)">⇚</div>
            <div @click="deletesele(index)"><i class="el-icon-delete"></i></div>
            <div @click="bottomlistbao(index)">⇛</div>
          </div>
        </div>
      </div>
      <div class="contentseleted" v-if="radio2=='医学会议'">
        <div class="contentweblist"  v-for="(item,index) in doctermeeting" :key="index">
          <div class="imagebacklist">
            <img :src="item.poster_url" alt="">
          </div>
          <div class="contenttitlelist">
            <div class="introduce">
              {{ item.title }}
            </div>
            <div class="titletime">
                <div class="location">地点</div>
                <div class="time">{{ item.edittime }}</div>
              </div>
              <div class="browse">
                <div class="backcontent"></div>
                <div class="viewtitle">{{ item.view_count }}</div>
              </div>
          </div>
          <div class="weblistcontentsele">
            <div @click="toplistbao(index)">⇚</div>
            <div @click="deletesele(index)"><i class="el-icon-delete"></i></div>
            <div @click="bottomlistbao(index)">⇛</div>
          </div>
        </div>
      </div>
      <div class="contentseleted" v-if="radio2=='医学直播'">
        <div class="contentweblist"  v-for="(item,index) in docterlive" :key="index">
          <div class="imagebacklist">
            <img :src="item.poster_url" alt="">
          </div>
          <div class="contenttitlelist">
            <div class="introduce">
              {{ item.title }}
            </div>
            <div class="titletime">
                <div class="location">地点</div>
                <div class="time">{{ item.edittime }}</div>
              </div>
              <div class="browse">
                <div class="backcontent"></div>
                <div class="viewtitle">{{ item.view_count }}</div>
              </div>
          </div>
          <div class="weblistcontentsele">
            <div @click="toplistbao(index)">⇚</div>
            <div @click="deletesele(index)"><i class="el-icon-delete"></i></div>
            <div @click="bottomlistbao(index)">⇛</div>
          </div>
        </div>
      </div>
      <div class="contentseleted" v-if="radio2=='医学视频'">
        <div class="contentweblist"  v-for="(item,index) in doctervideo" :key="index">
          <div class="imagebacklist">
            <img :src="item.poster_url" alt="">
          </div>
          <div class="contenttitlelist">
            <div class="introduce">
              {{ item.title }}
            </div>
            <div class="titletime">
                <div class="location">地点</div>
                <div class="time">{{ item.edittime }}</div>
              </div>
              <div class="browse">
                <div class="backcontent"></div>
                <div class="viewtitle">{{ item.view_count }}</div>
              </div>
          </div>
          <div class="weblistcontentsele">
            <div @click="toplistbao(index)">⇚</div>
            <div @click="deletesele(index)"><i class="el-icon-delete"></i></div>
            <div @click="bottomlistbao(index)">⇛</div>
          </div>
        </div>
      </div>
    </div>
    <div class="soncontentweb" v-if="dialogVisible">
      <el-dialog
        v-model="dialogVisible"
        :title="radio2"
        width="1200px"
        :before-close="handleClose"
      >
        <sonselected @childFn="parentFn" :selecterdlistweb="selecterdlistweb"></sonselected>
      </el-dialog>
    </div>
    <!-- 提交 -->
    <div class="gotoweblistsele">
      <div class="confirmsele" @click="gotowebsend">提交</div>
      <div class="cancellation">取消</div>
    </div>
  </div>
</template>
<script>
import sonselected from '../sonselected'
import qs from 'qs'
import { setjingxuan , getjingxuan } from '../../../../api/index'
export default {
  data(){
    return{
      radio2:'精选推荐',
      dialogVisible:false,
      selecterdlistweb:'',
      // 接收精选内容
      selectedwebcontent:[],
      tokenlistweb: 'wxe019e3ee85d0ced4',
      // 接收医学会议
      doctermeeting:[],
      // 医学直播
      docterlive:[],
      // 医学视频
      doctervideo:[]
    }
  },
  methods:{
    edmitselected(){
      console.log(this.radio2)
      this.dialogVisible=true
      this.selecterdlistweb=this.radio2
    },
    // 接收传值
    parentFn(e,a){
      console.log(e,a)
      if(a==0){
        if(this.selectedwebcontent.length<8){
          this.selectedwebcontent.push(e)
        }else {
          this.dialogVisible=false
          alert('最多添加八个')
        }
      }
      if(a==1){
        if(this.doctermeeting.length<8){
          this.doctermeeting.push(e)
        }else {
          this.dialogVisible=false
          alert('最多添加八个')
        }
      }
      if(a==2){
        if(this.docterlive.length<8){
          this.docterlive.push(e)
        }else {
          this.dialogVisible=false
          alert('最多添加八个')
        }
      }
      if(a==3){
        if(this.doctervideo.length<8){
          this.doctervideo.push(e)
        }else {
          this.dialogVisible=false
          alert('最多添加八个')
        }
      }
      
    },
    toplistbao(e){
      console.log(e,this.radio2)
      if (e != 0) {
        var arr=[]
        if(this.radio2=='精选推荐'){
            
            arr=this.selectedwebcontent
          }else if(this.radio2=='医学会议'){
            
            arr=this.doctermeeting
          }else if(this.radio2=='医学直播'){
            
            arr=this.docterlive
          }else if(this.radio2=='医学视频'){
            
            arr=this.doctervideo
          }
        // let arr = this.selectedwebcontent
        // console.log(this.signup)
        arr.splice(e - 1, 1, ...arr.splice(e, 1, arr[e - 1]));
        console.log(this.selectedwebcontent)
      } else {
        alert('已经位于第一位')
      }
    },
    bottomlistbao (e) {
      var app=[]
        if(this.radio2=='精选推荐'){
            
            app=this.selectedwebcontent
          }else if(this.radio2=='医学会议'){
            
            app=this.doctermeeting
          }else if(this.radio2=='医学直播'){
            
            app=this.docterlive
          }else if(this.radio2=='医学视频'){
            
            app=this.doctervideo
          }
      if (e == app.length-1) {
        alert('已经位于末尾')
      } else {
        var arr=[]
        if(this.radio2=='精选推荐'){
            
            arr=this.selectedwebcontent
          }else if(this.radio2=='医学会议'){
            
            arr=this.doctermeeting
          }else if(this.radio2=='医学直播'){
            
            arr=this.docterlive
          }else if(this.radio2=='医学视频'){
            
            arr=this.doctervideo
          }
        arr.splice(e, 1, ...arr.splice(e + 1, 1, arr[e]));
        //arr.splice(e + 1, 1, ...arr.splice(e, 1, arr[e + 1]));
        console.log(this.selectedwebcontent)
      }
      console.log(e)
    },
    // 删除
    deletesele(e){
      // if()
      if(this.radio2=='精选推荐'){
        this.selectedwebcontent.splice(e,1)
      
      }else if(this.radio2=='医学会议'){
        this.doctermeeting.splice(e,1)
      }else if(this.radio2=='医学直播'){
        this.docterlive.splice(e,1)
      }else if(this.radio2=='医学视频'){
        this.doctervideo.splice(e,1)
      }
      // this.selectedwebcontent.splice(e,1)
    },
    gotowebsend(){
      console.log('1')
      let type=''
      let jingxuan=[]
      if(this.radio2=='精选推荐'){
         type=0
         jingxuan=this.selectedwebcontent
      }else if(this.radio2=='医学会议'){
         type=1
         jingxuan=this.doctermeeting
      }else if(this.radio2=='医学直播'){
         type=2
         jingxuan=this.docterlive
      }else if(this.radio2=='医学视频'){
         type=3
         jingxuan=this.doctervideo
      }
      let app = qs.stringify({
        type: type,
        jingxuan: jingxuan,
        token: this.tokenlistweb,
      })
      setjingxuan(app).then(res => {
        console.log(res)
        if(res.code=='0'){
          alert('提交成功')
        }
      })
    },
    // 获取精选内容
    creaselectedlist(){
      let type=''
      if(this.radio2=='精选推荐'){
         type=0
      }else if(this.radio2=='医学会议'){
         type=1
         
      }else if(this.radio2=='医学直播'){
         type=2
      }else if(this.radio2=='医学视频'){
         type=3
      }
      let app = qs.stringify({
        type: type,
        token: this.tokenlistweb,
      })
      getjingxuan(app).then(res => {
        console.log(res)
        if(res.code==0&&res.jingxuan!=0&&res.jingxuan!="\"0\""){
          let result = JSON.parse(res.jingxuan); // jsonstr是json字符串
          console.log(result)
          if(this.radio2=='精选推荐'){
            this.selectedwebcontent=result
          }else if(this.radio2=='医学会议'){
            this.doctermeeting=result
          }else if(this.radio2=='医学直播'){
            this.docterlive=result
          }else if(this.radio2=='医学视频'){
            this.doctervideo=result
          }
        }
      })
    }
  },
  components:{
    sonselected
  },
  created(){
    this.creaselectedlist()
  },
  watch:{
    radio2(){
    this.creaselectedlist()
      // console.log(this.radio2)
    }
  }
}
</script>
<style >
.weblist {
  display: flex !important;
}
/* .selecterzong  */
.selected {
  width: 1200px;
  background-color: #fff;
  margin: 0 auto;
  padding-top: 20px;
}
/* 标题 */
.seletitle {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
/* 选择 */
.xuanzesele {
  margin-top: 20px;
}
/* 编辑 */
.contentsele {
  margin-top: 20px;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  flex-direction: row;
  justify-content: space-between;
}
.listcontentedit {
 color: red;
}
/* 具体内容 */
.contentseleted {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contentweblist {
  position: relative;
  width: 286px;
  height: 299px;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  margin-top: 22px;
  position: relative;
  border: 1px solid #ccc;
}
.imagebacklist {
  width: 100%;
  height: 163px;
}
.contenttitlelist {
  width: 256px;
  margin: 0 auto;
  margin-top: 20px
}
.introduce {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px
}
.imagebacklist img {
  width: 100%;
  height: 100%;
}
.titletime {
  display: flex;
  color: #6352F9;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  line-height: 14px;
  margin-top: 15px;
}
.time {
  margin-left: 12px;
}
.browse {
  margin-top: 10px;
}
.viewtitle {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 16px;
  /* margin-left: 3px; */
}
/* 
*测试
*/

.imsges {
  width: 120px;
  height: 67.5px;
}
/* 样式element */
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table th > .cell {
  text-align: center !important;
}


/* 移动 */
.weblistcontentsele {
  position: absolute;
  bottom: 0;
  width: 286px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
/* 提交 */
.gotoweblistsele {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%,0);
  display: flex;
  font-size: 16px;
  /* font-weight: 600; */
}
.confirmsele {
  padding: 8px 10px;
  background: #409EFF;
  color: #fff;
  border-radius: 5px;
  margin-right: 8px;
}
.cancellation {
  padding: 8px 10px;
  background: red;
  color: #fff;
  border-radius: 5px;
  margin-right: 8px;
}
</style>
