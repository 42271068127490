<template>
  <div>
    <div class="Carousel">
      <div class="car-titile">编辑/上传轮播图</div>
      <div class="car-list">
        <div class="car-uploadimage">
          <el-carousel :interval="5000" arrow="always" height="450px">
            <el-carousel-item v-for="item in lunboone" :key="item">
              <img :src="item.image" alt="" style="width: 100%; height: 100%" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="car-imageweblist">
          <div class="car-imagelist"  v-for="(item,index) in lunboone" :key="index">
            <img class="car-imagewebback" :src="item.image" alt="">
            <div class="car-weblistcontentsele">
              <div @click="toplistbao(index)">⇚</div>
              <div @click="deletesele(index)"><i class="el-icon-delete"></i></div>
              <div @click="bottomlistbao(index)">⇛</div>
            </div>
            <div class="car-inputlist">
              <input type="text" placeholder="输入网址" v-model="item.srcapp">
            </div>
          </div>
        </div>
        <div class="car-loderuploder">
          <el-upload
            :http-request="posterupload"
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="posterhandleAvatarSuccess"
            :before-upload="posterbeforeAvatarUpload"
            :on-change="posterlisttolive"
            :auto-upload="false"
          >
           
            <i  class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="car-setloder" @click="setloder">
          提交
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { getlunbotu , setlunbotu } from '../../../../api/index'
export default {
  data(){
    return{
      lunboone: [
        
      ],
      // 上传的图片
      postertype: '',
      posternamelist: '',
      posterimgfile: '',
      tokenlistweb:'wxe019e3ee85d0ced4',
    }
  },
  methods:{
    // 想左
    toplistbao(e){
      console.log(e)
      if (e != 0) {
        var arr=this.lunboone
        // let arr = this.selectedwebcontent
        // console.log(this.signup)
        arr.splice(e - 1, 1, ...arr.splice(e, 1, arr[e - 1]));
        console.log(this.lunboone)
      } else {
        alert('已经位于第一位')
      }
    },
    bottomlistbao (e) {
      var app=this.lunboone
        
      if (e == app.length-1) {
        alert('已经位于末尾')
      } else {
        var arr=this.lunboone
        arr.splice(e, 1, ...arr.splice(e + 1, 1, arr[e]));
        //arr.splice(e + 1, 1, ...arr.splice(e, 1, arr[e + 1]));
        // console.log(this.selectedwebcontent)
      }
      console.log(e)
    },
    // 删除
    deletesele(e){
      this.lunboone.splice(e,1)
    },
    // 上传图片
    posterbeforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      // return isJPG && isLt2M;
    },
    // // 进行中
    posterlisttolive (file) {
     if(this.lunboone.length<3){
      console.log(file.raw.type,this.lunboone.length)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.postertype = file.raw.type
        this.posternamelist = file.name
        // console.log(111)
        this.posterimgfile = file.raw
        this.posterupload()
      }
     }else {
      alert('最多三个轮播图')
     }
    },
    // 上传
    posterupload () {
      console.log('123')
      if (this.posterimgfile != '' && this.posterimgfile != undefined) {
        console.log(this.posterimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.posterimgfile
        let name = this.posternamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          let image = 'https://' + data.Location
          let id=this.lunboone.length
          let app = { id: id, image: image,srcapp:'' }
          this.lunboone.push(app)
          for (let i = 0; i < this.lunboone.length; i++) {
            this.lunboone[i].id = i
          }
          console.log(this.lunboone)
        });
      } else {
        console.log('第三个')
      }
    },
    // 获取轮播图
    getlunbo(){
      let app = qs.stringify({
        token :this.tokenlistweb
      })
      getlunbotu(app).then(res => {
        console.log(res)
        if(res.code!="1002"){
          this.lunboone=JSON.parse(res.lunbotu)
        }
      })
    },
    // 提交轮播图
    setloder(){
      let app = qs.stringify({
        token :this.tokenlistweb,
        lunbotu:this.lunboone
      })
      setlunbotu(app).then(res => {
        console.log(res)
        if(res.code=='0'){
          alert('提交成功')
        }
      })
    },
  },
  created(){
    this.getlunbo()
  }
}
</script>
<style scoped>
.car-titile {
  text-align: center;
  line-height: 60px;
  height: 60px;
  font-size: 20px;
  font-weight: 600;
}
.Carousel {
  width: 1200px;
  margin: 0 auto;
}
.car-imageweblist {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.car-imagelist {
  width: 320px;
  height: 180px;
  /* border: 1px solid #000; */
  margin-right: 20px;
  position: relative;
  margin-bottom: 30px;
}
.car-inputlist {
  width: 320px;
  height: 30px;
  position: absolute;
  bottom: -35px;
  left: 0;
}
.car-inputlist input {
  width: 320px;
  height: 30px;
 
}
.car-imagewebback {
  width: 100%;
  height: 100%;
}
.car-weblistcontentsele {
  position: absolute;
  bottom: 0;
  width: 320px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.car-uploadimage {
  width: 800px;
  height: 450px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.car-loderuploder {
  width: 360px;
  margin: 0 auto;
  margin-top: 30px;
}
.car-setloder {
  margin: 0 auto;
  margin-top: 20px;
  padding: 8px 10px;
  background: #409EFF;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  width: 80px;
}
</style>

