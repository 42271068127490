<template>
  <div class="creates">
    <!-- 选择类型 -->
    <div class="choice">
      <div class="choices">选择类型</div>
      <div>
        <el-button plain>视频直播</el-button>
        <el-button plain>图片直播</el-button>
        <el-button plain v-if="list">vr直播</el-button>
        <el-button plain v-if="list">语音直播</el-button>
        <el-button plain v-if="list">图文直播</el-button>
        <el-button plain icon="el-icon-arrow-right" v-if="bost" @click="more"
          >更多类型</el-button
        >
        <el-button
          plain
          icon="el-icon-arrow-left"
          v-if="list"
          @click="hide"
        ></el-button>
      </div>
    </div>
    <!-- 选择类型结束 -->
    <!-- 直播信息 -->
    <div class="information">
      <div class="broadcast">直播信息</div>
      <div>
        <!-- 直播名称 -->
        <div class="names">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="活动名称" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <!-- 时间 -->
        <div>
          <span class="timess">开始时间</span>
          <el-date-picker v-model="value1" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <!-- 频道 -->
        <div class="dist">
          <div class="classification">
            <el-dropdown>
              <span class="el-dropdown-link"> 所属频道 </span>
            </el-dropdown>
          </div>
          <div class="boxlistd">
            <el-dropdown :hide-on-click="false" style="line-height: 40px">
              <span class="el-dropdown-link">
                选择所属频道<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>选择所属频道</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
        <!-- 图片上传 -->
        <div class="upload">
          <div class="classification">
            <el-dropdown>
              <span class="el-dropdown-link"> 所属频道 </span>
            </el-dropdown>
          </div>
          <div class="upimg">
            <el-upload
              action="../images"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog v-model="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <div class="buittomlist">
              <el-button plain>本地上传</el-button>
              <el-button plain>素材库选择</el-button>
            </div>
          </div>
        </div>
        <!-- 创建 -->
        <div class="flexlist">
          <el-button plain @click="fromlist">返回</el-button>
          <el-button type="primary">立即创建</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: false,
      bost: true,
      value1: '',
      dialogImageUrl: '',
      // dialogVisible: '',
      ruleForm: {
        name: '',

      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    more () {
      this.list = true
      this.bost = false
    },
    hide () {
      this.list = false
      this.bost = true
    },
    fromlist () {
      this.$router.push('/form')
    }

  }
}
</script>
<style>
.creates {
  margin: 0 auto;
}
.choices {
  margin: 20px 0;
}
.information {
  margin-top: 20px;
}
.names {
  margin-top: 20px;
  width: 500px;
}
.el-form-item__label {
  width: 80px !important;
}
.timess {
  display: inline-block;
  width: 80px;
  margin-left: 8px;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
}
.classification {
  width: 80px;
  line-height: 40px;
  margin-left: 8px;
  padding: 0 12px 0 0;
}
.dist {
  height: 40px;
  margin-top: 20px;
  display: flex;
}
.boxlistd {
  width: 220px;
  border: 1px solid #ccc;
  text-align: center;
  margin-left: 8px;
}
.upload {
  display: flex;
  margin-top: 20px;
  width: 800px;
}
.upimg {
  margin-left: 8px;
  display: flex;
  /* flex-direction: column; */
}
.upimg .buittomlist {
  height: 40px;
  width: 360px;
  /* display: flex; */
  margin: 100px 20px;
}
/*  */
.flexlist {
  position: fixed;
  bottom: 20px;
  left: 450px;
  margin: 0 auto;
}
</style>