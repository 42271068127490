<template>
  <div>
    <div class="flexcread">
      <!-- 日历点击 -->
      <div v-if="paginglivelist">
        <el-button type="primary" size="small" @click="addlivelist"
          >添加直播</el-button
        >
      </div>
      <!-- 直播页面展示 -->
      <div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="id" label="id" width="180"> </el-table-column>
          <el-table-column label="频道封面" width="180">
            <template #default="scope">
              <img class="imsges" :src="scope.row.videopic" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="title" label="直播名称" width="200">
          </el-table-column>
          <el-table-column prop="livestatus" label="显示状态" width="100">
            <template #default="scope">
              <span class="el-dropdown-link">
                <span v-if="scope.row.livestatus === 0">未开始</span>
                <span v-else-if="scope.row.livestatus === 1">正在直播</span>
                <span v-else-if="scope.row.livestatus === 2">直播已结束</span>
                <span v-else-if="scope.row.livestatus === 3">直播中断</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="starttime" label="开始时间" width="150">
          </el-table-column>
          <el-table-column prop="typeofurl" label="类别">
            <template #default="scope">
              <span class="el-dropdown-link">
                <span v-if="scope.row.typeofurl === 0">视频</span>
                <span v-else-if="scope.row.typeofurl === 1"> iframe</span>
                <span v-else-if="scope.row.typeofurl === 2">直播</span>
                <span v-else-if="scope.row.typeofurl === 3">回放</span>
                <span v-else-if="scope.row.typeofurl === 4">垫片视频</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template v-slot="scope">
              <!-- <el-tag type="danger" @click="deletelive(scope.row)">删除</el-tag> -->
              <el-tag type="info" @click="startlive(scope.row)">开始</el-tag>
              <el-tag @click="edit(scope.row)">编辑</el-tag>
              <el-tag type="danger" @click="endlive(scope.row)">结束</el-tag>
            
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页数--点击去哪页 -->
      <div class="filexd" v-if="paginglivelist">
        <el-pagination
          layout="prev, pager, next"
          :total="paginationlive"
          page-size="20"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { change, } from "../../../api/index"
import qs from 'qs';
export default {
  data(){
    return {
      paginglivelist:true,
      tableData:'',
      paginationlive:''
    }
  },
  methods:{
    // 页数
    applist () {
      var id = localStorage.getItem('userid');
      let vals = this.valueslistlive
      let app = qs.stringify({
        pagenumber: vals,
        userid: id
      });
      change(app).then(res => {
        console.log(res);
        this.tableData = res.events
        this.paginationlive = res.totalCount
      })
    },
  },
  created(){
    this.applist()
  }
}
</script>
<style>
/* 直播封面 */
.imsges {
  width: 120px;
  height: 67.5px;
}
/* 浮动变成手势 */
.applist:hover {
  cursor: hand !important;
}
/* 操作 */
.el-tag {
  margin: 5px;
}
/* 直播编辑 */
.colorlive {
  margin: 3px 0;
  color: #409eff;
  font-size: 20px;
  font-weight: 600;
  border: 1px solid #409eff;
  width: 300px;
  text-align: center;
  background-color: #fff;
}
.el-button {
  padding: 8px 8px !important;
  min-height: 7px;
}
.el-dialog {
  width: 70% !important;
}
.avatar {
  width: 100%;
  height: 100%;
}
.filexd {
  position: fixed;
  bottom: 3px;
  left: 45%;
}
.el-pagination {
  background-color: #bebebe;
}
.lunbo {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.listtoavatar {
  margin: 10px;
  width: 160px;
  height: 90px;
  position: relative;
}
.listtoavatar .el-upload--text {
  width: 100% !important;
  height: 100% !important;
}
.lunbofonts {
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
}
.el-tag {
  padding: 0 7px !important;
}
</style>

