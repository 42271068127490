<template>
  <div>
    <!-- 主页 -->
    <!-- 导航 -->
    <div class="nav">
      <div v-if="spannav">
        <el-radio-group v-model="lable" style="margin: 5px 30px">
          <el-radio
            v-for="(item, index) in typelivewebs"
            :key="index"
            :label="item.id"
            @change="lableclass(item)"
            >{{ item.name }}</el-radio
          >
        </el-radio-group>
      </div>
      <div class="addarct">
        <el-button type="primary" size="small" @click="toaddimage" plain
          >添加文章</el-button
        >
        <el-button type="primary" size="small" @click="advertisement" plain
          >全局广告</el-button
        >
        <el-button type="primary" size="small" @click="advertqrcode" plain
          >全局二维码</el-button
        >
      </div>
    </div>
    <div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column fixed prop="id" label="序号" width="120" />
        <el-table-column prop="title" label="文章主题" width="130" />
        <el-table-column label="封面" width="160">
          <template #default="scope">
            <img class="imsges" :src="scope.row.poster_url" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="简介" width="120" />
        <el-table-column prop="cate_id" label="一级分类" width="200" />
        <el-table-column prop="author_id" label="作者id" width="120" />
        <el-table-column prop="sub_cate_id" label="报名表id" width="120" />
        <el-table-column prop="author_name" label="作者名字" width="120" />
        <el-table-column prop="teacher_id" label="讲者id" width="120" />
        <el-table-column prop="teacher_name" label="讲者名字" width="120" />
        <el-table-column prop="addtime" label="发布时间" width="120" />
        <el-table-column prop="edittime" label="最后编辑时间" width="180" />
        <el-table-column prop="sort" label="排序号" width="120" />
        <el-table-column prop="view_count" label="观看人数" width="100" />
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button
              v-if="scope.row.seconds == 1"
              type="text"
              size="small"
              @click="addClick(scope.row)"
              >恢复</el-button
            >
            <el-button
              v-else-if="scope.row.seconds == 0"
              type="text"
              size="small"
              @click="handleClick(scope.row)"
              >删除</el-button
            >
            <el-button type="text" size="small" @click="editimage(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="QRcodes(scope.row)"
              >二维码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="提示" v-model="dialogVisible" width="30%">
        <div class="flexqrcode">
          <div>
            <div>
              <div><span> 页面二维码 </span></div>
            </div>
            <div id="qrcode"></div>
            <div @click="copytow"><span> 复制 </span></div>
          </div>
          <div>
            <a :href="xiao">小程序专用二维码</a>
            <div id="qrcodes"></div>
            <div @click="xiaocopytow"><span> 复制 </span></div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="flooter">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        v-model:current-page="currentPage3"
        :page-size="20"
        layout="prev, pager, next ,jumper"
        :total="totalarticles"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import QRCode from "qrcodejs2";
import { getyzmessagesbypage , aritcletoggledisplay , mpqrcode } from '../../../api/index'
import qs from 'qs'
export default {
  data () {
    return {
      tableData: [],
      currentPage3: '',
      // 当前页面的总条
      totalarticles: '',
      typelivewebs: [
        { id: 0, name: '医知医汇', token: 'wxe019e3ee85d0ced4', idlist: '819202111' },
        { id: 1, name: '超声e站', token: 'wx1291e360dd0cf380', idlist: '819202115' },
        { id: 2, name: '超声助手', token: 'wx1291e360dd0cf381', idlist: '819202124' },
      ],
      lable: 0,
      // 文章的编辑token
      tokenlistweb: 'wxe019e3ee85d0ced4',
      idlist: "819202111",
      // da开
      dialogVisible: false,
      offical: '',
      // 是否导航
      spannav: false
    }
  },
  methods: {
    // 点击不显示
    handleClick (e) {
      console.log(e)
      let userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        articleid: e.id,
        display: e.seconds,//默认20
        userid: userid,
        token: this.tokenlistweb,
        cridential: '3'
      })
      aritcletoggledisplay(app).then(res => {
        console.log(res)
        if (res.code === '0') {
          e.seconds = res.display
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('失败');
        }
      })
    },
    // 点击显示
    addClick (e) {
      console.log(e)
      let userid = JSON.parse(localStorage.getItem("userid"));
      let app = qs.stringify({
        articleid: e.id,
        display: e.seconds,//默认20
        userid: userid,
        cridential: '3',
        token: this.tokenlistweb,
      })
      aritcletoggledisplay(app).then(res => {
        console.log(res)
        if (res.code === '0') {
          e.seconds = res.display
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error('失败');
        }
      })
    },
    webapp () {
      console.log('1')
    },
    // 页面加载文章
    article () {
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: 0,
        condition: '',
        token: this.tokenlistweb,
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
    // 添加
    toaddimage () {
      const { href } = this.$router.resolve({
        path: `/edmit`,
        query: { id: '000', token: this.tokenlistweb, }

      });
      window.open(href, '_blank');
    },
    // 进入全局广告
    advertisement () {
      const { href } = this.$router.resolve({
        path: `/advertisement`,
        query: { token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
    // 编辑
    editimage (e) {
      console.log(e)
      const { href } = this.$router.resolve({
        path: `/edmit`,
        query: { id: e.id, token: this.tokenlistweb, }
      });
      window.open(href, '_blank');
    },
    // 分页
    handleSizeChange (val) {
      console.log(`每页 ${val - 1} 条`);
    },
    // 进入多少页
    handleCurrentChange (val) {
      let vallist = `${val - 1}`
      // console.log(vallist);
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: vallist,
        token: this.tokenlistweb,
        condition: ''
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
    // 进入那个token
    lableclass (e) {
      console.log(e)
      this.tokenlistweb = e.token
      this.idlist = e.idlist
      this.article()
    },
    // 生成我二维码
    QRcodes (e) {
      console.log(e)
      this.dialogVisible = true
        let url = this.tokenlistweb
      console.log(url);
      // let a = url.substr(1);
      // console.log(a);
      let c = url.substring(0, 6);
      console.log(c)
      console.log(this.tokenlistweb)
      if(c=='wx1291'){
        this.offical = `https://wx1291e360dd0cf380.www.tongxianglive.cn/${this.idlist}/#/details?id=${e.id}&wxid=${this.tokenlistweb}`
      }else {
        this.offical = `https://${this.tokenlistweb}.www.tongxianglive.cn/${this.idlist}/#/details?id=${e.id}`
      }
      console.log(this.offical)
      let app = qs.stringify({
        video_id: e.id
      })
      mpqrcode(app).then(res => {
        console.log(res)
        this.xiao = res
        setTimeout(this.chengxuqrcode, 500)

      })
      setTimeout(this.imageqrcode, 500)
    },
    // 全局二维码
    advertqrcode () {
      this.dialogVisible = true
       let url = this.tokenlistweb
      console.log(url);
      // let a = url.substr(1);
      // console.log(a);
      let c = url.substring(0, 6);
      console.log(c)
      console.log(this.tokenlistweb)
      if(c=='wx1291'){
        this.offical = `https://wx1291e360dd0cf380.www.tongxianglive.cn/${this.idlist}/#/?wxid=${this.tokenlistweb}`
      }else {
        this.offical = `https://${this.tokenlistweb}.www.tongxianglive.cn/${this.idlist}/#/`
      }
      // this.offical = `https://${this.tokenlistweb}.www.tongxianglive.cn/${this.idlist}/`
      console.log(this.offical)
      setTimeout(this.imageqrcode, 500)

    },
    // 二维码
    imageqrcode () {
      document.getElementById("qrcode").innerHTML = "";
      // 生成
      new QRCode(document.getElementById("qrcode"), {
        text: this.offical, // 这里为动态二维码地址
        width: 100,
        height: 100,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 小程序码
    chengxuqrcode () {
      document.getElementById("qrcodes").innerHTML = "";
      // 生成
      new QRCode(document.getElementById("qrcodes"), {
        text: this.xiao, // 这里为动态二维码地址
        width: 100,
        height: 100,
        colorDark: "#000",
        colorLight: "#fff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    // 复制
    // 啦刘复之
    copytow () {
      // console.log(data)
      let res = this.offical
      console.log(res)
      var input = document.createElement("input"); // 直接构建input
      input.value = res // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input);
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    },
    xiaocopytow () {
      // console.log(data)
      let res = this.xiao
      console.log(res)
      var input = document.createElement("input"); // 直接构建input
      input.value = res // 设置内容
      console.log(input.value);
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input);
      ElMessage({
        showClose: true,
        message: '复制成功',
        type: 'success',
      })
    },
  },
  created () {
    const webspan = localStorage.getItem('quanxian')
    console.log(webspan)
    if (webspan == 1) {
      this.spannav = true
      this.typelivewebs = [
        { id: 0, name: '医知医汇', token: 'wxe019e3ee85d0ced4', idlist: '819202111' },
        { id: 1, name: '超声e 站', token: 'wx1291e360dd0cf380', idlist: '819202115' },
        {id:2,name:'基础知识',token:'wx1291e360dd0cf381',idlist:"819202124"},
        {id:3,name:'心脏超声',token:'wx1291e360dd0cf382',idlist:"819202124"},
        {id:4,name:'妇产超声',token:'wx1291e360dd0cf383',idlist:"819202124"},
        {id:5,name:'胸部超声',token:'wx1291e360dd0cf384',idlist:"819202124"},
        {id:6,name:'腹部超声',token:'wx1291e360dd0cf385',idlist:"819202124"},
        {id:7,name:'血管超声',token:'wx1291e360dd0cf386',idlist:"819202124"},
        {id:8,name:'肌骨超声',token:'wx1291e360dd0cf387',idlist:"819202124"},
        {id:9,name:'浅表超声',token:'wx1291e360dd0cf388',idlist:"819202124"},
        {id:10,name:'小儿超声',token:'wx1291e360dd0cf389',idlist:"819202124"},
        {id:11,name:'重症超声',token:'wx1291e360dd0cf390',idlist:"819202124"},
      ]
      this.tokenlistweb = 'wxe019e3ee85d0ced4'
      this.idlist = "819202111"
    } else if (webspan == 3) {
      this.spannav = false
      this.typelivewebs = [
      ]
      this.tokenlistweb = 'wxe019e3ee85d0ced4'
      this.idlist = "819202111"
    } else if (webspan == 4) {
      this.spannav = false
      this.typelivewebs = [
      ]
      this.tokenlistweb = 'wx1291e360dd0cf380'
      this.idlist = "819202115"
    }
    this.article()
  }
}
</script>
<style scoped>
/* 导航 */
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.addarct {
  text-align: right;
  margin: 5px;
}
/* 直播封面 */
.imsges {
  width: 120px;
  height: 67.5px;
}
/* 样式element */
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table th > .cell {
  text-align: center !important;
}
/* 分页 */
.flooter {
  position: fixed;
  width: 100%;

  bottom: 0;
}
/deep/ .el-pagination {
  margin: 0 auto;
  width: 20%;
}
.flexqrcode {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/deep/.el-radio-group {
      display: flex;
    /* width: 60%; */
    flex-wrap: wrap
}
/deep/.addarct {
  display: flex;
}
</style>
