<template>
  <div>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本设置" name="first">
          <!-- 内部表单 -->
          <div class="boxlists">
            <div class="formlist">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="活动名称" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="直播时间">
                  <el-date-picker
                    v-model="value2"
                    type="datetime"
                    placeholder="选择日期时间"
                    align="right"
                    :shortcuts="shortcuts"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="直播方式">
                  <el-input
                    :disabled="true"
                    v-model="ruleForm.classification"
                  ></el-input>
                </el-form-item>
                <el-form-item label="详情封面">
                  <div class="updatelist">
                    <el-upload
                      action="https://jsonplaceholder.typicode.com/posts/"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                    <div class="buittomlist">
                      <el-button plain>本地上传</el-button>
                      <el-button plain>素材库选择</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="观看方式">
                  <el-tabs type="border-card">
                    <el-tab-pane label="公开">
                      <div>
                        <div>
                          <span>公开直播间</span>
                        </div>
                        <div>
                          <span>设置回放方式：</span>
                          <div class="radio">
                            <el-radio v-model="radio" label="1"
                              >结束后回放（结束话题后才生成回放视频）</el-radio
                            >
                            <el-radio v-model="radio" label="2"
                              >实时回放
                              （将已推流的视频进行实时回放，暂停直播后可生成回放）</el-radio
                            >
                            <el-radio v-model="radio" label="3"
                              >结束后不回放（直播结束后不生成回放视频）</el-radio
                            >
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="加密">
                      <div>
                        <div>密码：</div>
                        <el-input
                          v-model="input"
                          placeholder="请输入内容"
                        ></el-input>
                        <div>
                          <span>设置回放方式：</span>
                          <div class="radio">
                            <el-radio v-model="radio" label="1"
                              >结束后回放（结束话题后才生成回放视频）</el-radio
                            >
                            <el-radio v-model="radio" label="2"
                              >实时回放
                              （将已推流的视频进行实时回放，暂停直播后可生成回放）</el-radio
                            >
                            <el-radio v-model="radio" label="3"
                              >结束后不回放（直播结束后不生成回放视频）</el-radio
                            >
                          </div>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </el-form-item>
                <el-form-item label="话题外观">
                  <div>
                    <div class="images">
                      <img src="../images/5.png" alt="" />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <el-button class="el-icon-document" type="primary"
                    >保存</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="简历设置" name="second">
          <div class="boxlists">
            <div class="formlist">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="主播" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="主播介绍" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="主播介绍" prop="name">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="el-icon-document" type="primary"
                    >保存</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="分享设置" name="third">
          <div class="boxlists">
            <div class="formlist">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="分享封面" prop="name">
                  <div class="updatelist">
                    <el-upload
                      action="https://jsonplaceholder.typicode.com/posts/"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog v-model="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                    <div class="buittomlist">
                      <el-button plain>本地上传</el-button>
                      <el-button plain>素材库选择</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="主播" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="分享描述" prop="name">
                  <el-input
                    type="textarea"
                    :rows="2"
                    placeholder="请输入内容"
                    v-model="textarea"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="el-icon-document" type="primary"
                    >保存</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      ruleForm: {
        name: '',
        shortcuts: '',
        value2: '',
        classification: '横屏直播',
        dialogImageUrl: 'https://img0.baidu.com/it/u=1631582372,4013670020&fm=11&fmt=auto&gp=0.jpg'
      },
      rules: {
        name: [
          { required: true, message: '请输入直播名称', trigger: 'blur' },
          { min: 1, max: 20, message: '请输入内容', trigger: 'blur' }
        ]
      },
      radio: '1',
      input: '',
      textarea: ''
    }
  },

}
</script>
<style>
.boxlists {
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid red; */
}
.formlist {
  width: 520px;
}
.updatelist {
  display: flex;
}
.buittomlist {
  height: 50px;
  margin: 100px 0 0 20px;
}
.radio {
  height: 100px;
}
.el-radio {
  display: block !important;
  margin: 3px;
}
</style>
