<template>
  <div>
    <div class="addlive">添加直播</div>

    <div class="titlelivelist">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!-- 直播名称 -->
        <el-form-item label="直播名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!-- 浏览量 -->
        <!-- <el-form-item label="浏览量">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.input"
            :disabled="true"
          >
          </el-input>
        </el-form-item> -->
        <el-form-item label="开始日期时间">
          <el-date-picker
            v-model="ruleForm.datelist"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="ruleForm.value1" type="date">
          </el-date-picker>
        </el-form-item>
        <!-- 类别 -->
        <el-form-item label="类别">
          <div class="titletodo">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ appdownlist
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu v-for="item in dropdown" :key="item.id">
                  <el-dropdown-item @click="wholelive(item.id, item.title)">{{
                    item.title
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-form-item>
        <el-form-item label="照片">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-change="listtolive"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div class="bottomlist">
        <el-button type="danger" @click="cancellive">取消</el-button>
        <el-button type="primary" @click="addlivelists">添加</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { addlistlive } from "../../api/index"
export default {
  data () {
    return {
      ruleForm: {
        name: '',
        times: "",
        number: '',
        imgage: '',
        value1: '',
        datelist: '',
        input: '0',
        typeofvideo: ''

      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 6, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ],
      },
      value1: '',
      // 是否立即上传

      fileList: [
        { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' },
        { name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
      filst: '',
      imageUrl: '',
      // 类别
      dropdown: [
        { id: 1, title: '医疗' },
        { id: 2, title: '跨国' },
        { id: 3, title: '其他' }
      ],
      // 下拉显示的值
      appdownlist: '请选择一个类型'
    }
  },
  methods: {
    // 点击下拉菜单
    wholelive (a, e) {
      console.log(a, e)
      this.appdownlist = e
    },
    addlist () {
      let res = this.$route.query
      console.log(res.title)
      this.datelist = res.title
      console.log()

      let applist = new Date()
      this.value1 = applist
      console.log(this.value1.toLocaleTimeString())
    },
    // 点击取消
    cancellive () {
      this.$router.push('/live')
    },
    addlivelist () {
      let applistliveapp = {
        id: 123,
        datelist: this.datelist,
        times: this.value1.toLocaleTimeString(),
        img: this.imgage
      }
      console.log(applistliveapp)
    },
    // 测试
    handleAvatarSuccess (res, file) {
      console.log(file.raw)
      // this.imageUrl = URL.createObjectURL(file.raw);
      // this.imageUrl = file.raw;
      // console.log(this.imageUrl)
      // console.log(res)
    },
    beforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 进行中
    listtolive (file, filelist) {
      console.log(file, filelist, 1)
      const isJPG = file.raw.type === 'image/jpeg';
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      console.log(file)
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.namelist = file.name
        this.imgfile = file.raw
        this.imageUrl = URL.createObjectURL(file.raw)
        // this.imageUrl = filelist.map(item => item)
        // console.log(111)
      }
    },
    // 点击上传
    addlivelists () {
      const birth = this.ruleForm.value1
      const value2 = this.ruleForm.datelist
      if (typeof (birth) == 'string') {
        // console.log(123)
        // let applistliveto = birth
        // console.log(applistliveto)
      } else {
        const date = {
          year: birth.getFullYear(),
          month: birth.getMonth() + 1,
          date: birth.getDate(),
        };
        const newmonth = date.month > 10 ? date.month : "0" + date.month;
        const day = date.date > 10 ? date.date : "0" + date.date;
        this.cilcktimes = date.year + "/" + newmonth + "/" + day;
        let applistliveto = date.year + "-" + newmonth + "-" + day;
        console.log(applistliveto)
        this.ruleForm.value1 = applistliveto
      }
      if (typeof (value2) == 'string') {
        // console.log(456)
        // const app=value2
      } else {
        const date = {
          year: value2.getFullYear(),
          month: value2.getMonth() + 1,
          date: value2.getDate(),
          hour: value2.getHours(),//得到小时
          minu: value2.getMinutes(),//得到分钟
          sec: value2.getSeconds(),//得到秒
        };
        const newmonth = date.month > 10 ? date.month : "0" + date.month;
        const day = date.date > 10 ? date.date : "0" + date.date;
        const hour = date.hour > 10 ? date.hour : "0" + date.hour
        const minu = date.minu > 10 ? date.minu : "0" + date.minu
        const sec = date.sec > 10 ? date.sec : "0" + date.sec
        let app = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
        console.log(app)
        this.ruleForm.datelist = app
      }
      let applisttolive = {
        data: {
          name: this.ruleForm.name,
          times: this.ruleForm.value1,
          timelist: this.ruleForm.datelist,
          number: this.ruleForm.input,
          image: this.imageUrl,
          category: this.appdownlist
        }
      }
      if (this.ruleForm.name.length > 6 || this.ruleForm.name.length < 1) {
        // console.log('')
        this.$message.error('直播名称输入不合法');
      } else if (this.ruleForm.datelist == null || this.ruleForm.value1 == null) {
        this.$message.error('日期输入不合法');
      } else if (this.appdownlist == '请选择一个类型') {
        this.$message.error('请选择类型');
      } else if (this.imageUrl == '') {
        this.$message.error('请上传封面');
      } else {
        addlistlive(applisttolive).then(res => {
          console.log(res);
          if (res.id != '' || res.requestId != '') {
            this.$message({
              type: 'success',
              message: '添加成功!'
            });
            this.$router.push('/live')
          }
        })
      }
    }
  },
  created () {
    this.addlist()
  }
}
</script>
<style>
.titletodo {
  border: 1px solid #dcdfe6;
  text-align: center;
  width: 220px;
  border-radius: 4px;
  /* color: #606266; */
}
.addlive {
  text-align: center;
  color: blue;
  font-size: 26px;
  margin: 20px;
}
.titlelivelist {
  width: 600px;
  margin: auto;
}
.bottomlist {
  text-align: center;
}
.imges {
  width: 100%;
  height: 100%;
}
.imsges {
  width: 100px;
  height: 100px;
  position: relative;
}
.list {
  position: absolute;
  font-size: 20px;
  color: red;
  top: 0px;
  right: 0;
}
.avatar {
  width: 100%;
  height: 100%;
}
</style>
