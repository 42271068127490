<template>
  <div>
    <div class="flexnavto">创建图文跳转</div>
    <div class="todaylistweb">
      <el-button
        type="primary"
        size="small"
        @click="getinto"
        v-if="adverliveimage"
        >新建</el-button
      >
      <el-button type="danger" size="small" @click="getinto" v-else
        >返回</el-button
      >
    </div>
    <!-- 类表 -->
    <div class="todaylistweb" v-if="adverliveimage">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="频道封面" width="180">
          <template #default="scope">
            <img class="imsges" :src="scope.row.picture" alt="" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名字" width="180">
        </el-table-column>
        <el-table-column prop="link" label="名字" width="180">
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button type="text" size="small" @click="editimage(scope.row)"
              >使用</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加 -->
    <div class="concentlist" v-else>
      <el-form ref="formRef" label-width="120px">
        <el-form-item label="封面图片">
          <el-upload
            :http-request="lunbouploadone"
            class="listtoavatar"
            :show-file-list="false"
            :on-success="lunbouploadoneAvatarSuccess"
            :before-upload="beforlunbouploadone"
            :on-change="lunbouploadonelisttolive"
            :auto-upload="false"
          >
            <img v-if="webimage" :src="picture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="名字">
          <el-input v-model="namelist"></el-input>
        </el-form-item>
        <el-form-item label="广告链接">
          <el-input v-model="vodm3u8"></el-input>
        </el-form-item>
      </el-form>
      <div class="concentlist">
        <div class="sbumit">
          <el-button size="medium" @click="sumbit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { savetuwenlink, gettuwenlink } from '../../../api/index'
export default {
  data () {
    return {
      tableData: [
        { id: 1, videopic: 'https://img.mianfeiwendang.com/pic/65133e4129b6446aa22c9f9f/1-810-jpg_6-1080-0-0-1080.jpg', name: '第一条' }
      ],
      // 轮播图
      // 图片
      webimage: false,
      // 轮播上传1.名字
      lunboonenamelist: '',
      // 图标
      lunbooneimgfile: '',
      // 后缀
      lunboonetype: '',
      // 上传id
      liveidimage: '',
      // 上传的图片
      picture: '',
      // 链接
      vodm3u8: '',
      // 名字
      namelist: '',
      adverliveimage: true,
      liveid: ''
    }
  },
  props: ['adverimage'],
  methods: {
    // 点击使用
    editimage (e) {
      // console.log(e)
      this.$emit('childFn', e)
    },
    // 上传图片
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.picture = 'https://' + data.Location
          this.webimage = true
          console.log(this.picture)
        });
      } else {
        console.log(123)
      }
    },
    // 提交
    sumbit () {
      if (this.namelist != '' && this.picture != '' && this.vodm3u8 != '') {
        var id = localStorage.getItem('userid');
        // var token = localStorage.getItem('token');
        console.log('提交')
        let app = qs.stringify({
          txliveid: this.liveid,
          userid: id,
          cridential: '2',
          name: this.namelist,
          picture: this.picture,
          link: this.vodm3u8,
        })
        savetuwenlink(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.adverliveimage = true
            this.imageadertisement()
          }
        })
      } else {
        alert('请填入内容')
      }
    },
    // 获取页面数据
    imageadertisement () {
      // var id = localStorage.getItem('userid');
      let app = qs.stringify({
        txliveid: this.liveid,
      })
      gettuwenlink(app).then(res => {
        console.log(res)
        this.tableData = res.tuwenlinks
      })
    },
    // 进入新建还是
    getinto () {
      this.adverliveimage = !this.adverliveimage
    }
  },
  created () {
    this.liveid = this.adverimage
    console.log(this.liveid)
    this.imageadertisement()
  }
}
</script>
<style scoped>
/* 导航 */
.flexnavto {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 20px;
}
.todaylistweb {
  margin: 10px auto;
}
.imsges {
  width: 120px;
  height: 67.5px;
}
/* 新建 */
.concentlist {
  margin: 20px 0;
  width: 600px;
  margin: 0 auto;
}
/* 提交 */
.sbumit {
  text-align: center;
}
.concentlist /deep/.el-input {
  width: 77%;
}
.listtoavatar /deep/ .el-upload img {
  width: 100%;
  height: 100%;
}
</style>
