<template>
  <div>
    <div class="sontitleselected">
      <!-- {{ selecterdlistweb }} -->
      <div class="lodersendweblist">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column fixed prop="id" label="序号" width="120" />
          <el-table-column prop="title" label="文章主题" width="130" />
          <el-table-column label="封面" width="160">
            <template #default="scope">
              <img class="imsges" :src="scope.row.poster_url" alt="" />
            </template>
          </el-table-column>
          <el-table-column prop="intro" label="简介" width="180" />
          <el-table-column prop="cate_id" label="一级分类" width="200" />
          <el-table-column prop="view_count" label="观看人数" width="200" />
          <el-table-column fixed="right" label="操作" width="180">
            <template #default="scope">
              <el-button
              v-if="scope.row.seconds == 0"
                type="text"
                size="small"
                @click="addClick(scope.row,scope.$index)"
                >设置精选</el-button
              >
              <el-button
              v-else-if="scope.row.seconds == 1"
                type="text"
                size="small"
                >禁止设置</el-button
              >
             
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flooter">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:current-page="currentPage3"
          :page-size="20"
          layout="prev, pager, next ,jumper"
          :total="totalarticles"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getyzmessagesbypage  } from '../../../../api/index'
import qs from 'qs'
export default {
  data(){
    return{
      tableData:[
        {id: 900000024, title: '测试活动1',poster_url:"https://txcos-1306887409.cos.ap-beijing.myqcloud.com/1647078895796-37-%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220302180850a.jpg", intro: '这是一个重大活动', cate_id: 0, sub_cate_id: 0,author_name:"医知医汇",teacher_id:0,teacher_name:'',addtime:"2022-03-12 17:55:47",edittime: "2022-03-24 17:23:09",sort:0,seconds:0,selectead:1,view_count:4,} ,
        {id: 900000025, title: '测试活动2',poster_url:"https://txcos-1306887409.cos.ap-beijing.myqcloud.com/1647078895796-37-%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220302180850a.jpg", intro: '这是一个重大活动', cate_id: 0, sub_cate_id: 0,author_name:"医知医汇",teacher_id:0,teacher_name:'',addtime:"2022-03-12 17:55:47",edittime: "2022-03-24 17:23:09",sort:0,seconds:0,selectead:1,view_count:4,} ,
        {id: 900000026, title: '测试活动3',poster_url:"https://txcos-1306887409.cos.ap-beijing.myqcloud.com/1647078895796-37-%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220302180850a.jpg", intro: '这是一个重大活动', cate_id: 0, sub_cate_id: 0,author_name:"医知医汇",teacher_id:0,teacher_name:'',addtime:"2022-03-12 17:55:47",edittime: "2022-03-24 17:23:09",sort:0,seconds:0,selectead:1,view_count:4,} ,
      ],
      // 精选推荐
      selecterlist:[],
      tokenlistweb:'wxe019e3ee85d0ced4',
      currentPage3: '',
      // 当前页面的总条
      totalarticles: '',
      condtion:''
    }
  },
  props:['selecterdlistweb'],
  methods:{
    addClick(e){
      if(this.selecterdlistweb=='精选推荐'){
        this.$emit("childFn",e,0)
      }
      if(this.selecterdlistweb=='医学会议'){
        this.$emit("childFn",e,1)
      }
      if(this.selecterdlistweb=='医学直播'){
        this.$emit("childFn",e,2)
      }
      if(this.selecterdlistweb=='医学视频'){
        this.$emit("childFn",e,3)
      }
      // console.log(e,a)
      // this.tableData[a].selectead=0
    },
     // 页面加载内容
     article () {
     
      if(this.selecterdlistweb=='精选推荐'){
        console.log(this.selecterdlistweb,'123')
        this.condtion=0
      }
      if(this.selecterdlistweb=='医学会议'){
        console.log(this.selecterdlistweb)
        this.condtion=1
      }
      if(this.selecterdlistweb=='医学直播'){
        console.log(this.selecterdlistweb)
        this.condtion=2
      }
      if(this.selecterdlistweb=='医学视频'){
        this.condtion=3
        // console.log(this.selecterdlistweb)
      }
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: 0,
        condition: '',
        catetory:this.condtion,
        token: this.tokenlistweb,
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
     // 分页
     handleSizeChange (val) {
      console.log(`每页 ${val - 1} 条`);
    },
    // 进入多少页
    handleCurrentChange (val) {
      let vallist = `${val - 1}`
      // console.log(vallist);
      let app = qs.stringify({
        numperpage: 20,//默认20
        pagenumber: vallist,
        token: this.tokenlistweb,
        condition: ''
      })
      getyzmessagesbypage(app).then(res => {
        console.log(res)
        this.tableData = res.articles
        this.totalarticles = res.totalCount
      })
    },
  },
  mounted(){
    console.log(this.selecterdlistweb)
  },
  created(){
    this.article()
  }
}
</script>
<style>

</style>
