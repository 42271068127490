<template>
  <div>
    <div>
      <!-- 频道信息 -->
      <div class="channellistto">
        <i class="iconfont icon-shuxian"></i>
        <span>频道信息</span>
      </div>
      <!-- 设置 -->
      <div class="setuplist" style="width: 600px">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本设置" name="first">
            <!-- 基本设置 -->
            <div class="baicslist">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="频道名称：" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="频道类型：" prop="inputlist">
                  <div class="textlist">
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        {{ app
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu
                          v-for="item in textlist"
                          :key="item.id"
                        >
                          <el-dropdown-item
                            @click="domlist(item.id, item.title)"
                            >{{ item.title }}</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </el-form-item>
                <el-form-item label="频道封面：">
                  <div class="flexinglist">
                    <div class="imglist"></div>
                    <div class="buttomlist">
                      <el-button plain>朴素按钮</el-button>
                      <el-button plain>朴素按钮</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="商业模式：">
                  <div class="titlelistto">
                    <div class="title">开启后频道简介不显示直播间入口</div>
                    <div>
                      <el-switch
                        v-model="value"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                      >
                      </el-switch>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="配置管理" name="second">配置管理</el-tab-pane>
        </el-tabs>
      </div>
      <!-- 保存 -->
      <div class="savelist">
        <el-button>返回</el-button>
        <el-button type="primary" @click="savelist">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      activeName: 'first',
      text: '',
      value: '1',
      ruleForm: {
        name: '',

      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        inputlist: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 5, message: '请选择合法', trigger: 'blur' }
        ]
      },
      textlist: [
        { id: 1, title: '公开' },
        { id: 2, title: '固定收费' },
        { id: 3, title: '按月收费' },
        { id: 4, title: '加密' },
      ],
      app: '公开'
    }
  },
  methods: {
    savelist () {
      if (this.activeName == "first") {
        console.log(1)
      }
      else if (this.activeName == "second") {
        console.log(2)
      }
    },
    domlist (e, c) {
      console.log(e, c)
      this.app = c
    }
  },

}
</script>
<style>
.channellistto {
  /* font-size: 20px; */
  font-weight: 900;
}
.channellistto span {
  font-size: 20px;
}
.channellistto i {
  color: blue;
  font-size: 22px;
}
.flexinglist {
  display: flex;
  height: 200px;
}
.buttomlist {
  height: 100px;
  margin: 160px 10px;
}
.imglist {
  border: 1px solid #ccc;
  width: 200px;
  height: 200px;
}
.titlelistto {
  display: flex;
}
.titlelistto .title {
  font-size: 14px;
  /* color: ; */
  margin: 0 10px 0 0;
}
.savelist {
  position: fixed;
  bottom: 50px;
  left: 800px;
}
.textlist {
  width: 200px;
  text-align: center;
  border: 1px solid #ccc;
}
</style>
