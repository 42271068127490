<template>
  <div>
    <!-- 广告 -->
    <div>
      <!-- nav广告 -->
      <div class="navadver">全局广告</div>
      <div class="concentlist">
        <el-form ref="formRef" label-width="120px">
          <!-- <el-form-item label="选择平台">
            <el-radio-group v-model="lable" style="margin: 5px 30px">
              <el-radio
                v-for="(item, index) in typelivewebs"
                :key="index"
                :label="item.id"
                @change="lableclass(item.name)"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="封面图片">
            <el-upload
              :http-request="lunbouploadone"
              class="listtoavatar"
              :show-file-list="false"
              :on-success="lunbouploadoneAvatarSuccess"
              :before-upload="beforlunbouploadone"
              :on-change="lunbouploadonelisttolive"
              :auto-upload="false"
            >
              <img v-if="webimage" :src="picture" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="广告链接">
            <el-input v-model="vodm3u8"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!-- 提交 -->
      <div class="concentlist">
        <div class="sbumit">
          <el-button size="medium" @click="sumbit">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getglobalad, updateglobalad } from '../../../api/index'
import qs from 'qs'
export default {
  data () {
    return {
      // 轮播图
      // 图片
      webimage: false,
      // 轮播上传1.名字
      lunboonenamelist: '',
      // 图标
      lunbooneimgfile: '',
      // 后缀
      lunboonetype: '',
      // 上传id
      liveidimage: '',
      // 上传的token
      tokenlist: "",
      // 上传的图片
      picture: '',
      // 链接
      vodm3u8: '',
      typelivewebs: [
        { id: 0, name: '医治' },
        { id: 1, name: '医治2' }
      ],
      lable: '',
      tokenlistweb: ''
    }
  },
  methods: {
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.picture = 'https://' + data.Location
          this.webimage = true
          console.log(this.picture)
        });
      } else {
        console.log(123)
      }
    },
    // 提交
    sumbit () {
      if (this.picture != '' && this.vodm3u8 != '') {
        let userid = JSON.parse(localStorage.getItem("userid"));
        let app = qs.stringify({
          id: this.liveidimage,
          picture: this.picture,
          link: this.vodm3u8,
          token: this.tokenlist,
          adtype: 2,
          userid: userid,
          cridential: 2
        })
        updateglobalad(app).then(res => {
          console.log(res)
          if (res.code == 9999) {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.$router.push('/homearticle')
          }
        })
      }
    },
    // 取回
    Retrieve () {
      console.log(this.tokenlistweb)
      let app = qs.stringify({
        token: this.tokenlistweb,
      })
      getglobalad(app).then(res => {
        console.log(res)
        this.picture = res.ads[0].picture
        this.liveidimage = res.ads[0].id
        this.tokenlist = res.ads[0].token
        this.vodm3u8 = res.ads[0].link
        if (res.ads[0].picture != '') {
          this.webimage = true
          console.log(this.picture)
        }
      })
    },
    lableclass (e) {
      console.log(e)
    }
  },
  created () {

    let res = this.$route.query
    this.tokenlistweb = res.token
    console.log(this.tokenlistweb)
    this.Retrieve()
  }
}
</script>
<style scoped>
.navadver {
  width: 600px;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  color: #000;
}
.concentlist {
  margin: 20px 0;
  width: 600px;
  /* margin: 0 auto; */
}
/* 提交 */
.sbumit {
  text-align: center;
}
.concentlist /deep/.el-input {
  width: 77%;
}
.listtoavatar /deep/ .el-upload img {
  width: 100%;
  height: 100%;
}
</style>
