<template>
  <div>
    <!-- 编辑 -->
    <div class="spanlivelist">
      <span> 编辑内容</span>
    </div>
    <div class="titlelivelist">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="直播名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="开始时间">
          <!-- <el-input v-model="ruleForm.times"></el-input> -->
          <el-date-picker v-model="ruleForm.value2" type="datetime">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="开始日期">
          <el-date-picker v-model="ruleForm.value1" type="date">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="浏览量">
          <el-input v-model="ruleForm.number"></el-input>
        </el-form-item>
        <el-form-item label="类别">
          <div class="titletodo">
            <el-dropdown>
              <span class="el-dropdown-link">
                {{ appdownlist
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu v-for="item in dropdown" :key="item.id">
                  <el-dropdown-item @click="wholelive(item.id, item.title)">{{
                    item.title
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-form-item>
        <el-form-item label="照片">
          <el-upload
            ref="upload"
            class="avatar-uploader"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-change="listtolive"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottomlist">
      <el-button type="danger" @click="bottom">取消456</el-button>
      <el-button type="danger" @click="cancellive">取消</el-button>
      <el-button type="primary" @click="addlivelist">更改</el-button>
    </div>
  </div>
</template>
<script>
import { editlive } from "../../api/index"
export default {
  data () {
    return {
      liveedit: [],
      ruleForm: {
        name: '',
        times: "",
        number: '',
        value1: '',
        value2: '',
        ide: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 6, message: '长度在 3 到 6 个字符', trigger: 'blur' }
        ],
      },
      value1: '',
      imageUrl: '',
      titleliveimg: false,
      dropdown: [
        { id: 1, title: '医疗' },
        { id: 2, title: '跨国' },
        { id: 3, title: '其他' }
      ],
      // 下拉显示的值
      appdownlist: ''
    }
  },
  methods: {
    // 点击下拉菜单
    wholelive (a, e) {
      console.log(a, e)
      this.appdownlist = e
    },
    // 头像
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      // console.log(this.imageUrl)
      // console.log(res)
    },
    beforeAvatarUpload (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 进行中
    listtolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.imageUrl = URL.createObjectURL(file.raw)
        // console.log(111)
      }
    },
    // 点击取消
    cancellive () {
      this.$router.push('/live')
    },
    // 点击更改
    addlivelist () {
      // console.log(e)
      let id = this.ruleForm.ide
      console.log(this.ruleForm.value1)
      const birth = this.ruleForm.value1
      const value2 = this.ruleForm.value2
      if (typeof (birth) == 'string') {
        // console.log(123)
        // let applistliveto = birth
        // console.log(applistliveto)
      } else {
        const date = {
          year: birth.getFullYear(),
          month: birth.getMonth() + 1,
          date: birth.getDate(),
        };
        const newmonth = date.month > 10 ? date.month : "0" + date.month;
        const day = date.date > 10 ? date.date : "0" + date.date;
        this.cilcktimes = date.year + "/" + newmonth + "/" + day;
        let applistliveto = date.year + "-" + newmonth + "-" + day;
        console.log(applistliveto)
        this.ruleForm.value1 = applistliveto
      }
      if (typeof (value2) == 'string') {
        // console.log(456)
        // const app=value2
      } else {
        const date = {
          year: value2.getFullYear(),
          month: value2.getMonth() + 1,
          date: value2.getDate(),
          hour: value2.getHours(),//得到小时
          minu: value2.getMinutes(),//得到分钟
          sec: value2.getSeconds(),//得到秒
        };
        const newmonth = date.month > 10 ? date.month : "0" + date.month;
        const day = date.date > 10 ? date.date : "0" + date.date;
        const hour = date.hour > 10 ? date.hour : "0" + date.hour
        const minu = date.minu > 10 ? date.minu : "0" + date.minu
        const sec = date.sec > 10 ? date.sec : "0" + date.sec
        let app = date.year + "-" + newmonth + "-" + day + " " + hour + ":" + minu + ":" + sec;
        console.log(app)
        this.ruleForm.value2 = app
      }
      let applistlive = {
        data: {
          // id: this.ruleForm.ide,
          name: this.ruleForm.name,
          timelist: this.ruleForm.value2,
          number: this.ruleForm.number,
          times: this.ruleForm.value1,
          image: this.imageUrl,
          category: this.appdownlist
        }
      }
      // console.log(this.ruleForm.name.length)
      if (this.ruleForm.name.length > 6 || this.ruleForm.name.length < 1) {
        // console.log('')
        this.$message.error('直播名称输入不合法');
      }
      else if (this.ruleForm.value2 == null || this.ruleForm.value1 == null) {
        this.$message.error('日期不能为空');
      } else if (this.ruleForm.number == '') {
        this.$message.error('浏览量不能为空');
      } else if (this.appdownlist == '请选择一个类型') {
        this.$message.error('请选择一个类型');
      } else {
        editlive(id, applistlive).then(res => {
          console.log(res);
          // console.log(this.ruleForm.ide);
          if (res.updated == 1) {
            this.$message({
              type: 'success',
              message: '更改成功!'
            });
            this.$router.push('/live')
          } else {
            this.$message({
              type: 'success',
              message: '更改失败!'
            });
          }
        })
      }

    },
    bottom () {
      console.log(this.ruleForm.value2)
    }
  },
  created () {
    let res = this.$route.query
    this.liveedit = res
    this.ruleForm.ide = res.id
    this.ruleForm.name = res.name
    this.ruleForm.value2 = res.timelist
    this.ruleForm.value1 = res.times
    this.ruleForm.number = res.number
    this.imageUrl = res.image
    this.appdownlist = res.category
    console.log(res)

  }
}
</script>
<style>
/* 下拉菜单 */
.titletodo {
  border: 1px solid #dcdfe6;
  text-align: center;
  width: 220px;
  border-radius: 4px;
  /* color: #606266; */
}
.spanlivelist {
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}
.spanlivelist span {
  border: 1px solid blue;
  display: inline-block;
  color: blue;
  margin: 20px;
  padding: 5px;
}
.titlelivelist {
  width: 600px;
  margin: auto;
}
.bottomlist {
  text-align: center;
}
.avatar {
  width: 100%;
  height: 100%;
}
</style>