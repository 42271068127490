<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">后台管理系统</div>
      <el-form :model="param" ref="login" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="username">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            placeholder="password"
            v-model="param.password"
            @keyup.enter="submitForm()"
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
        <div class="timelist">
          <el-checkbox v-model="checked1" >30天免登录</el-checkbox>
          <!-- <el-checkbox v-model="checked2" >7天免登录</el-checkbox> -->
        </div>
        <!-- <p class="login-tips">Tips : 用户名和密码随便填。</p> -->
      </el-form>
    </div>
  </div>
</template>

<script>
import { longin } from "../api/index";
// import md5 from 'js-md5'
import qs from 'qs';
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      param: {
        username: "",
        password: "",
      },
      rules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" }
        ]
      },
      longin: '',
      checked1:false,
    };
  },
  created () {
    this.$store.commit("clearTags");
  },
  methods: {
    submitForm () {
      // let namephone = /^((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|(147))\d{8}$/;
      // if (!namephone.test(this.param.username)) {
      //   console.log(1)
      //   ElMessage.error('账号输入错误')
      // } else
      if (this.param.password == '') {
        ElMessage.error('请输入密码')
      } else {
        let md = this.param.username + this.param.password
        // let asd = window.atob(md)

        let password = window.btoa(md)
        console.log(md, password)
        let app = qs.stringify({
          userid: this.param.username,
          password: password,
          cridential: this.param.username
        })
        longin(app).then(res => {
          console.log(res);
          if (res.code == 9999) {
            console.log(1)
            let token = res.cridential
            let userid = this.param.username
            localStorage.setItem('token', token)
            localStorage.setItem('userid', userid)
            localStorage.setItem('quanxian', res.quanxian)
            if(this.checked1==true){
              let aData = new Date();
              aData.setDate(aData.getDate()+30)
              let dateValue = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              console.log(dateValue)
              localStorage.setItem('timedata', dateValue)
            }else if(this.checked1==false){
              let aData = new Date();
              aData.setDate(aData.getDate()+1)
              let dateValue = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
              console.log(dateValue)
              localStorage.setItem('timedata', dateValue)
            }
            this.$router.push("/")
          }
          else if (res.code == 1200) {
            ElMessage.error('账号或密码错误')
          }
        })
        this.oppent()
      }
      // await this.$axios.post('https://www.fastmock.site/mock/c198cda921f6c3c5111bcde6092e883c/api/api/longin').then(res => {
      //   console.log(res.data);
      //   // console.log(content);
      // })
      //this.$refs.login.validate(valid => {
      // if (valid) {
      //   longin(this.param).then(res => {
      //     console.log(res);
      //     this.longin = res.data.longin;
      //     console.log(this.longin);
      //     // this.pageTotal = res.pageTotal || 50;

      //     if (this.longin == 1) {
      //       this.$message.success("登录成功");
      //       localStorage.setItem("ms_username", this.param.username);
      //       
      //     } else {
      //       this.$message.error("账号或者密码错误");
      //       return false;
      //     }
      //   });
      // } else {
      //   this.$message.error("请输入账号和密码");
      //   return false;
      // }
      // });
    },
    submitForms(){
      let aData = new Date();
      console.log(aData) 
      let app=aData.setDate(aData.getDate()+30)
      console.log(app)
      let dateValue = aData.getFullYear() + "-" + (aData.getMonth() + 1) + "-" + aData.getDate();
      console.log(dateValue)
      let aDatas = new Date();
      let dateValues = aDatas.getFullYear() + "-" + (aDatas.getMonth() + 1) + "-" + aDatas.getDate();
      console.log(dateValues)
      if(dateValue>dateValues){
        console.log('aData')
      }else if(dateValues>dateValue){
        console.log('退出重新登录')
      }
    },
    // 遮罩层
    oppent () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      setTimeout(() => {
        loading.close()
      }, 2000)
    },
  }
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>