<template>
  <div class="management">
    <!-- 左侧导航 -->
    <div class="leftnav">
      <el-menu
        default-active="2"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item index="1" @click="from">
          <i class="el-icon-arrow-left"></i>
          <template #title>话题列表</template>
        </el-menu-item>
        <el-menu-item index="2" @click="info">
          <template #title>基本信息设置</template>
        </el-menu-item>
        <el-menu-item index="3" @click="advanced">
          <template #title>直播参数设置</template>
        </el-menu-item>
        <el-menu-item index="4">
          <template #title>高级设置</template>
        </el-menu-item>
        <el-menu-item index="5" @click="broadcasting">
          <template #title>开播设置</template>
        </el-menu-item>
      </el-menu>
    </div>
    <!-- 右侧内容 -->
    <div class="topnav">
      <!-- 上面 -->
      <div class="green">
        <div class="hand">
          <div>
            <div>新手必读如何创建直播</div>
          </div>
          <div>
            <span class="endlist">结束</span>
            <span>横屏直播</span>
            <span>开始时间：12:00</span>
          </div>
        </div>
        <div class="copy">
          <div>
            <i class="iconfont icon-fuzhi ilist"></i>
            <span>复制链接</span>
          </div>
          <div>
            <el-popover placement="top" :width="160" v-model:visible="visible">
              <img src="../images/6.png" alt="" />
              <template #reference>
                <div>
                  <i class="iconfont icon-erweima ilist"></i>
                  <span>h5观看</span>
                </div>
              </template>
            </el-popover>
          </div>
          <div>
            <el-popover
              placement="top"
              :width="160"
              v-model:visibles="visibles"
            >
              <img src="../images/6.png" alt="" />
              <template #reference>
                <div>
                  <i class="iconfont icon-xiaochengxu ilist"></i>
                  <span>小程序</span>
                </div>
              </template>
            </el-popover>
          </div>
          <div>
            <el-button type="primary" plain @click="broadcasting"
              >开播设置</el-button
            >
          </div>
        </div>
      </div>
      <!-- 下方路由 -->
      <div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<style>
.management {
  display: flex;
  /* height: 100%; */
}
.leftnav {
  /* flex: 2; */
  width: 150px;
  height: 800px;
  /* position: fixed; */
  border: 1px solid #ccc;
  border-right: none;
  background-color: #fff;
}
.topnav {
  border: 1px solid #ccc;
  width: 100%;
  background-color: white;
}
.green {
  display: flex;
  padding: 30px;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.hand div {
  margin: 5px 0;
}
.green span {
  font-size: 14px;
  margin: 0 6px 0 0;
  color: #ccc;
}
.green .endlist {
  color: orange;
}
.copy {
  display: flex;
}
.ilist {
  text-align: center;
  margin-bottom: 3px;
  font-size: 28px;

  display: block;
}
.copy div {
  margin-left: 16px;
}
</style>
<script>
export default {
  data () {
    return {
      visible: false,
      visibles: false
    }
  },
  methods: {
    info () {
      this.$router.push('/information')
    },
    from () {
      this.$router.push('/form')
    },
    broadcasting () {
      this.$router.push('/broadcasting')
    },
    advanced () {
      this.$router.push('/advanced')
    }
  }
}
</script>