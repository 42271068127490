<template>
  <div>
    <div class="titletext">图文{{ liveid }}</div>
    <div class="titlebottom">
      <el-button size="small" type="primary" @click="upIntoimage"
        >打开广告选择</el-button
      >
      <el-button size="small" type="primary" @click="deletIntoimage"
        >取消广告选择</el-button
      >
    </div>
    <div>
      <div>当前广告选择为：{{ adver }}</div>
      <el-dialog title="提示" v-model="list" width="60%" center>
        <advertisement
          @childFn="parentFns"
          :adverimage="adverimage"
          :key="comlist"
        ></advertisement>
      </el-dialog>
    </div>
    <div class="imagearcate">
      <div class="quilimage">
        <quil :quil="quil" :key="com" @childFn="parentFn"></quil>
      </div>
      <div class="webphonelook">
        <div class="ohonrmoni" v-for="item in webapp" :key="item.id">
          <div class="phone" v-html="item.cons"></div>
          <div class="bottom">
            <el-button @click="imageapp(item)" type="primary" size="small">
              使用</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import advertisement from '../advertisement/index.vue'
import quil from '../../quil/quil.vue'
import qs from 'qs'
import { viewmenu, menu, gettuwen, savetuwen } from '../../../api/index'
export default {
  data () {
    return {
      liveid: '',
      webapp: [],
      userid: '',
      token: '',
      // 菜单
      webimagelive: '',
      quil: '',
      com: '',
      list: false,
      // 当前选择的广告
      adver: '',
      // 选择
      adverimage: '',
      comlist: '',
      // 是否选择了广告
      advertisementlive: 0
    }
  },
  methods: {
    parentFn (e) {
      console.log(e)
      // let a = { id: 0, list: 1, title: '图文', cons: e }
      // let b = this.webapp.length
      // a.id = b
      // this.webapp.push(a)
      for (let i = 0; i < this.webimagelive.length; i++) {
        if (this.webimagelive[i].list == 1) {
          this.webimagelive[i].cons = e
          console.log(this.webimagelive)
          let app = qs.stringify({
            txliveid: this.liveid,
            userid: this.userid,
            cridential: this.token,
            adid: this.advertisementlive,
            data: this.webimagelive
          })
          savetuwen(app).then(res => {
            console.log(res)
            if (res.code == 0) {
              // this.webmenuabb = false
              alert('创建成功')
              this.listimageone()
              // this.interaction = ''
            } else {
              alert('创建失败，请重试')
            }
          })
        }
      }
      console.log(this.webapp)
    },
    // 替换提交
    imageapp (e) {
      console.log(e)
      console.log(this.webimagelive)
      for (let i = 0; i < this.webimagelive.length; i++) {
        if (this.webimagelive[i].list == 1) {
          this.webimagelive[i].cons = e.cons
          console.log(this.webimagelive)
          let app = qs.stringify({
            txliveid: this.liveid,
            userid: this.userid,
            cridential: this.token,
            data: this.webimagelive
          })
          menu(app).then(res => {
            console.log(res)
            if (res.code == 0) {
              this.webmenuabb = false
              alert('创建成功')
              // this.interaction = ''
            } else {
              alert('创建失败，请重试')
            }
          })
        }
      }
      // var Re = /(<img[^>]*>)/gi
      // let arr = e.match(Re)
      // console.log(arr)

    },
    // 加载菜单
    loadlive () {
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: this.userid,
        cridential: this.token,
      })
      viewmenu(app).then(res => {
        console.log(res)
        this.webimagelive = eval("(" + res.data + ")")
        this.listimageone()
      })
    },
    // 加载图文一
    listimageone () {
      let app = qs.stringify({
        txliveid: this.liveid,
        userid: this.userid,
        cridential: this.token,
      })
      gettuwen(app).then(res => {
        console.log(res)
        for (let i = 0; i < res.tuwen.length; i++) {
          res.tuwen[i].id = i
        }
        this.webapp = res.tuwen
        console.log(this.webapp)
        // this.webimagelive = eval("(" + res.data + ")")
      })
    },
    // 打开广告选择
    upIntoimage () {
      this.list = true
      this.adverimage = this.liveid
      this.comlist = new Date().getTime()
    },
    // 取消广告选择
    deletIntoimage () {
      this.advertisementlive = 0
      this.adver = ''
    },
    // 传回的广告id，名字
    parentFns (e) {
      console.log(e)
      this.advertisementlive = e.id
      this.adver = e.name
      this.list = false
    }
  },
  created () {
    let res = this.$route.query
    this.liveid = res.id
    var id = localStorage.getItem('userid');
    var token = localStorage.getItem('token');
    this.userid = id
    this.token = token
    this.loadlive()
  },
  components: {
    quil,
    advertisement
  }
}
</script>
<style scoped>
.webphonelook {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  height: 350px;
}
.ohonrmoni {
  margin: 0 10px;
}
.webphonelook .phone {
  width: 160px;
  margin: 10px 10px;
  height: 290px;
  overflow: scroll;
  border: 1px solid #ccc;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.webphonelook .bottom {
  text-align: center;
}
/* 文字 */
.titletext {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
.titlebottom {
  margin: 10px 0;
}
</style>
