<template>
  <div>
    <div class="function">
      <div
        class="imgtitle"
        v-for="item in imglist"
        :key="item.id"
        @click="tablist(item.id)"
      >
        <img :src="item.imgsrc" alt="" />
        <div>{{ item.title }}</div>
      </div>
    </div>
    <!-- 数据 -->
    <div class="datalist">
      <div class="title">数据分析</div>
      <div class="function">
        <div
          class="imgtitle"
          v-for="item in imgsrclist"
          :key="item.id"
          @click="tablist(item.id)"
        >
          <img :src="item.imgsrc" alt="" />
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      value: false,
      imglist: [
        {
          id: 1, title: '自定义菜单', imgsrc: require('../images/list-04.png')
        },
        {
          id: 2, title: '评论管理', imgsrc: require('../images/list-18.png')
        },
        {
          id: 3, title: '广告', imgsrc: require('../images/list-03.png')
        },
        {
          id: 4, title: '报名表', imgsrc: require('../images/list-06.png')
        },
        {
          id: 5, title: '访问权限', imgsrc: require('../images/list-23.png')
        },
        {
          id: 6, title: '白名单', imgsrc: require('../images/whiteuser.png')
        },
        {
          id: 7, title: '多多会场', imgsrc: require('../images/list-60.png')
        },
        {
          id: 8, title: '签到管理', imgsrc: require('../images/list-63.png')
        }
      ],
      imgsrclist: [
        {
          id: 1, title: '自定义菜单', imgsrc: require('../images/list-04.png')
        },
        {
          id: 2, title: '评论管理', imgsrc: require('../images/list-18.png')
        },
        {
          id: 3, title: '广告', imgsrc: require('../images/list-03.png')
        },
        {
          id: 4, title: '报名表', imgsrc: require('../images/list-06.png')
        },
        {
          id: 5, title: '访问权限', imgsrc: require('../images/list-23.png')
        },
        {
          id: 6, title: '白名单', imgsrc: require('../images/whiteuser.png')
        },
        {
          id: 7, title: '多多会场', imgsrc: require('../images/list-60.png')
        },
        {
          id: 8, title: '签到管理', imgsrc: require('../images/list-63.png')
        }
      ],
      idlist: {
        1: 'from',
        2: 'list'
      },
      titlelist: ''
    }
  },
  methods: {
    tablist (e) {
      // this.titlelist = e
      // console.log(this.titlelist)
      // console.log(this.idlist)
      // console.log(this.idlist.Object)
      // this.idlist.Object.key(item){
      if (e == 1) {
        // console.log(this.idlist)
        // this.$router.push('/form')
      }
    }
    // if (this.titlelist == this.idlist) {
    //   console.log(this.idlist)
    //   this.$router.push('/form')
    // }
    // }
  }
}
</script>
<style>
.function {
  /* border-bottom: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
}
.imgtitle {
  /* flex: 8; */
  margin: 10px 10px;
  width: 100px;
  height: 100px;
  padding: 40px;
  /* border: 1px solid red; */
  text-align: center;
  font-size: 14px;
  color: #666;
}
.imgtitle div {
  margin: 5px 0;
}
.imgtitle:hover {
  box-shadow: 5px 5px 20px -10px #000;
  /* border: 1px solid red; */
}
.title {
  padding: 10px 5px;
}
</style>