<template>
  <div>
    <!-- 导航 -->
    <div class="navimage">
      <!-- 添加的内容 -->
      <div class="setUp">
        <!-- 设置 -->
        <el-form ref="formRef" :model="nameimage" label-width="120px">
          <el-form-item label="地点" v-if="tokenname">
            <el-input v-model="nameimage.author_name"></el-input>
          </el-form-item>
          <el-form-item label="作者名字" v-else>
            <el-select
              v-model="nameimage.author_name"
              placeholder="选择作者"
              size="large"
            >
              <el-option
                v-for="item in author"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文章标题">
            <el-input v-model="nameimage.title"></el-input>
          </el-form-item>
          <el-form-item label="文章简介">
            <el-input v-model="nameimage.intro"></el-input>
          </el-form-item>
          <el-form-item label="讲者名字">
            <el-input v-model="nameimage.teacher_name"></el-input>
          </el-form-item>
          <el-form-item label="活动时间">
            <el-date-picker
              v-model="nameimage.vodm3u8version"
              type="datetime"
            />
          </el-form-item>
          <el-form-item label="封面图片">
            <el-upload
              :http-request="lunbouploadone"
              class="listtoavatar"
              :show-file-list="false"
              :on-success="lunbouploadoneAvatarSuccess"
              :before-upload="beforlunbouploadone"
              :on-change="lunbouploadonelisttolive"
              :auto-upload="false"
            >
              <img v-if="webimage" :src="nameimage.poster_url" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="个性广告">
            <el-button type="primary" size="small " @click="eliminate" plain
              >清除</el-button
            >
            <div>
              <el-upload
                :http-request="lunbouploadtwo"
                class="listtoavatar"
                :show-file-list="false"
                :on-success="lunbouploadtwoAvatarSuccess"
                :before-upload="beforlunbouploadtwo"
                :on-change="lunbouploadtwolisttolive"
                :auto-upload="false"
              >
                <img
                  v-if="webimagetwo"
                  :src="nameimage.defaultvideo"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-input
                v-model="nameimage.vodm3u8"
                placeholder="请输入广告链接"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-select
              v-model="nameimage.cate_id"
              placeholder="选择分类"
              size="large"
            >
              <el-option
                v-for="item in titlename"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加标签">
            <el-select
              multiple
              multiple-limit="5"
              v-model="nameimage.hash"
              placeholder="选择标签"
              size="large"
            >
              <el-option
                v-for="item in labelimage"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查看评论">
            <div v-if="nameimage.width == 0">
              <el-button type="primary" size="small" @click="opencomment(1)"
                >开启</el-button
              >
              (现在是关闭状态，点击开启)
            </div>
            <div v-if="nameimage.width == 1">
              <el-button type="primary" size="small" @click="opencomment(0)"
                >关闭</el-button
              >
              (现在是开启状态，点击关闭)
            </div>
          </el-form-item>
          <el-form-item v-if="nameimage.width == 1" label="进行评论">
            <div v-if="nameimage.height == 0">
              <el-button type="primary" size="small" @click="openconduct(1)"
                >开启</el-button
              >
              (现在是关闭状态，点击开启)
            </div>
            <div v-if="nameimage.height == 1">
              <el-button type="primary" size="small" @click="openconduct(0)"
                >关闭</el-button
              >
              (现在是开启状态，点击关闭)
            </div>
          </el-form-item>
          <el-form-item label="富文本">
            <el-switch
              v-model="webquil"
              inline-prompt
              active-text="是"
              inactive-text="否"
              @click="weblists"
            >
            </el-switch>

            <div v-if="webquil">
              <quil @childFn="parentFn" :quil="quil"></quil>
            </div>
            <div class="quillive" v-else v-html="nameimage.content"></div>
          </el-form-item>
          <el-form-item label="留言审核" v-if="liveid != '000'">
            <el-button type="primary" size="small" @click="examinecomment"
              >进入留言管理页面</el-button
            >
          </el-form-item>
          <el-form-item label="报名表">
            <!-- <el-input v-model="nameimage.intro"></el-input> -->
            <el-table :data="tableData" height="300" border style="width: 100%">
              <el-table-column prop="id" label="序号" width="180">
              </el-table-column>
              <el-table-column prop="title" label="名称" width="180">
              </el-table-column>
              <el-table-column label="地址">
                <template v-slot="scope">
                  <el-button
                    type="text"
                    v-if="nameimage.sub_cate_id == scope.row.id"
                    @click="livedeletbaoming(scope.row)"
                    >取消报名表</el-button
                  >
                  <el-button v-else type="text" @click="aleatbaoming(scope.row)"
                    >选择</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-form>
        <div class="submit">
          <el-button @click="weblist" type="primary" round>提交</el-button>
        </div>
        <!-- 富文本 -->
      </div>
      <!-- <div class="quil-listweb">
        <div class="quil-quil">
          <quil @childFn="parentFn" :quil="quil"></quil>
        </div>
        <div class="quil-xianshi"></div>
      </div> -->
    </div>
  </div>
</template>
<script>
import quil from '../components/quil/index.vue'
import { baoming, getyzarticle, updateyzarticle } from '../../../api/index'
import qs from 'qs'
export default {
  data () {
    return {
      webquil: false,
      // 设置绑定的名字
      nameimage: {
        id: 0, author_name: '', title: '', intro: '', cate_id: '', teacher_name: '', poster_url: '', hash: [], content: '', sub_cate_id: '', width: 0, height: 0, defaultvideo: '', vodm3u8: '', vodm3u8version: ''
      },
      // 几级辩题
      titlename: [
        
      ],
      // biao题
      labelimage: [
      ],
      // 作者名字
      author: [],
      value3: '',
      quil: '',
      // 图片
      webimage: false,
      // 轮播图
      // 轮播上传1.名字
      lunboonenamelist: '',
      // 图标
      lunbooneimgfile: '',
      // 后缀
      lunboonetype: '',
      // 轮播图
      // 轮播上传1.名字
      lunbotownamelist: '',
      // 图标
      lunbotowimgfile: '',
      // 后缀
      lunbotowtype: '',
      // 图片
      webimagetwo: false,
      // baomingbiao 
      tableData: [],
      // 报名表绑定的
      boxbaoming: '',
      // 文章id
      liveid: '',
      // token
      tokenlistweb: "",
      tokenname:true
    }
  },
  methods: {
    // 内容提交
    weblist () {
      if (this.liveid === '000') {
        console.log('1')
        let userid = JSON.parse(localStorage.getItem("userid"));
        let app = qs.stringify({
          articleid: 0,
          userid: userid,
          cridential: '3',
          data: this.nameimage,
          token: this.tokenlistweb
        })
        updateyzarticle(app).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.$router.push('/homearticle')
          } else {
            this.$message.error('失败');
          }
        })
      } else {
        console.log(this.nameimage)
        let userid = JSON.parse(localStorage.getItem("userid"));
        console.log(userid)
        let app = qs.stringify({
          articleid: this.liveid,
          userid: userid,
          cridential: '3',
          data: this.nameimage,
          token: this.tokenlistweb
        })
        updateyzarticle(app).then(res => {
          console.log(res)
          if (res.code === '0') {
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.$router.push('/homearticle')
          } else {
            this.$message.error('失败');
          }
        })
      }

    },
    parentFn (e) {
      this.nameimage.content = e
      this.$message({
        message: '保存成功',
        type: 'success'
      });
      this.webquil = false
      console.log(e)
    },
    // 打开富文本
    weblists () {
      console.log(this.webquil)
      // this.webquil
      if (this.webquil == true) {
        console.log(this.quil)
        this.quil = this.nameimage.content
      }
    },
    // 轮播图
    // 轮播
    beforlunbouploadone (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadonelisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunboonetype = file.raw.type
        this.lunboonenamelist = file.name
        // console.log(111)
        this.lunbooneimgfile = file.raw
        this.lunbouploadoneupload()
        // this.lunboone = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadoneupload () {
      if (this.lunbooneimgfile != '' && this.lunbooneimgfile != undefined) {
        console.log(this.lunbooneimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbooneimgfile
        let name = this.lunboonenamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.nameimage.poster_url = 'https://' + data.Location
          this.webimage = true
        });
      } else {
        console.log(123)
      }
    },
    // 轮播图
    // 轮播
    beforlunbouploadtwo (file) {
      console.log(file)
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 和png 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 轮播图1进行
    lunbouploadtwolisttolive (file) {
      console.log(file.raw.type)
      const isJPG = file.raw.type === 'image/jpeg';
      const isPng = file.raw.type === 'image/png'
      const isLt2M = file.raw.size / 1024 / 1024 < 2;
      console.log(URL.createObjectURL(file.raw))
      if (!isJPG && !isPng) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      } else if (!isLt2M) {
        console.log(isJPG)
        this.$message.error('上传头像图片大小不能超过 2MB!');
      } else {
        this.lunbotowtype = file.raw.type
        this.lunbotownamelist = file.name
        // console.log(111)
        this.lunbotowimgfile = file.raw
        this.lunbouploadtwoupload()
        // this.lunbotow = URL.createObjectURL(file.raw)
      }
    },
    // 轮播1上传
    lunbouploadtwoupload () {
      if (this.lunbotowimgfile != '' && this.lunbotowimgfile != undefined) {
        console.log(this.lunbotowimgfile)
        var COS = require('cos-js-sdk-v5');
        var cos = new COS({
          SecretId: 'AKIDUtGahY9UYLr52i7F6gz1mGTTDi4KOBiy',
          SecretKey: '1YtymIZZJmytXuzoMddMozsUpthEVVDe'
        });
        this.cosdata = cos
        let data = new Date().getTime()
        let random = Math.floor(Math.random() * 100);
        let image = this.lunbotowimgfile
        let name = this.lunbotownamelist
        let namelist = data + '-' + random + '-' + name
        console.log(namelist)
        this.cosdata.putObject({
          Bucket: 'txcos-1306887409', /* 必须 */
          Region: 'ap-beijing', /* 存储桶所在地域，需要后端提供 */
          Key: namelist, /* 必须 */
          StorageClass: 'STANDARD',
          Body: image, // 上传文件对象
          // FilePath: 'tx4s',
          onProgress: (progressData) => {
            console.log(JSON.stringify(progressData));
            // console.log(progressData)
          }
        }, (err, data) => {
          console.log(err || data);
          console.log(data)
          this.nameimage.defaultvideo = 'https://' + data.Location
          this.webimagetwo = true
        });
      } else {
        console.log(123)
      }
    },
    // 清除广告内容
    eliminate () {
      this.nameimage.defaultvideo = ''

    },
    // 页面加载获取信息
    information () {
      baoming().then(res => {
        // console.log(res)
        this.tableData = res.baomingbiaolist
      })
      if (this.liveid === '000') {
        console.log(this.tokenlistweb)
        let app = qs.stringify({
          token: this.tokenlistweb,
        })
        getyzarticle(app).then(res => {
          console.log(res)
          this.labelimage = res.tags
          // this.titlename = res.categories
          if(this.tokenname==false){
            this.author = res.authors
          }
        })
      } else {
        console.log('编辑内容')
        let app = qs.stringify({
          articleid: this.liveid,
          token: this.tokenlistweb,
        })
        getyzarticle(app).then(res => {
          console.log(res)
          this.nameimage = res.article
          this.labelimage = res.tags
          // this.titlename = res.categories
          if(this.tokenname==false){
            this.author = res.authors
          }
          console.log(this.labelimage)
          if (res.article.poster_url != '') {
            this.webimage = true
          } else {
            this.webimage = false
          }
        })

      }
    },
    // 选择
    aleatbaoming (e) {
      this.$confirm('此操作将直播与报名表绑定, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(e.id)
        this.nameimage.sub_cate_id = e.id
        this.$message({
          type: 'success',
          message: '绑定成功'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消绑定'
        });
      });
    },
    // 取消
    livedeletbaoming (e) {
      this.nameimage.sub_cate_id = ''
      console.log(e)
    },
    // 是否查看评论
    opencomment (e) {
      console.log(e)
      this.nameimage.width = e
    },
    // 是否进行评论
    openconduct (e) {
      this.nameimage.height = e
    },
    // 进入留言管理
    examinecomment () {
      console.log(this.liveid)
      this.$router.push(
        {
          path: '/leaving',
          query: { liveid: this.liveid }
        }
      )
    }
  },
  components: {
    quil
  },
  created () {
    let res = this.$route.query
    this.liveid = res.id
  var str=res.token
   console.log(str)
    this.tokenlistweb = res.token
    console.log(this.tokenlistweb)
    this.information()
str=str.substr(str.length-3,3)
console.log(str)
if(str==='380'){
  this.tokenname=false
  console.log('这是超')
    this.titlename=[
        {
          id: 0,
          name: "全部",
          src: "el-icon-files livezhi",
          blur: "#8470FF",
          liveid: 0,
        },
        {
          id: 1,
          name: "直播",
          src: "el-icon-video-camera livezhi",
          blur: "#87CEFF",
          liveid: 1,
        },
        {
          id: 2,
          name: "会议",
          src: "el-icon-phone-outline livezhi",
          blur: "#87CEFF",
          liveid: 2,
        },
        {
          id: 3,
          name: "培训",
          src: "el-icon-s-marketing livezhi",
          blur: "#87CEFF",
          liveid: 3,
        },
        {
          id: 4,
          name: "前沿动态",
          blur: "#87CEFF",
          src: "iconfont icon-18qianyanjishu livezhi",
          liveid: 4,
        },
      ]
}else if(str==='ed4'){
  this.tokenname=true
  this.titlename=[
        {
          id: 0,
          name: "全部",
          src: "el-icon-files livezhi",
          blur: "#8470FF",
          liveid: 0,
        },
        {
          id: 1,
          name: "医学会议",
          src: "el-icon-video-camera livezhi",
          blur: "#87CEFF",
          liveid: 1,
        },
        {
          id: 2,
          name: "医学直播",
          src: "el-icon-phone-outline livezhi",
          blur: "#87CEFF",
          liveid: 2,
        },
        {
          id: 3,
          name: "医学资讯",
          src: "el-icon-s-marketing livezhi",
          blur: "#87CEFF",
          liveid: 3,
        },
      ]
}else {
  this.tokenname=false
  // console.log('砂夹石')
   this.titlename=[
        {
          id: 0,
          name: "全部",
          src: "el-icon-files livezhi",
          blur: "#8470FF",
          liveid: 0,
        },
        {
          id: 1,
          name: "超声百科",
          src: "el-icon-video-camera livezhi",
          blur: "#87CEFF",
          liveid: 1,
        },
        {
          id: 2,
          name: "专家讲堂",
          src: "el-icon-phone-outline livezhi",
          blur: "#87CEFF",
          liveid: 2,
        },
        {
          id: 3,
          name: "病例分析",
          src: "el-icon-s-marketing livezhi",
          blur: "#87CEFF",
          liveid: 3,
        },
         {
          id: 4,
          name: "心得笔记",
          src: "el-icon-s-marketing livezhi",
          blur: "#87CEFF",
          liveid: 3,
        },
      ]
}
   
  },
}
</script>
<style scoped>
.setUp {
  width: 600px;
}
.quillive {
  border: 1px solid #dcdfe6;
  background: #fff;
  line-height: 40px;
  padding: 0 15px;
  color: #606266;
}
.submit {
  width: 100%;
  text-align: center;
}
/* 图片 */
.avatar {
  width: 100%;
  height: 100%;
}
.quil-listweb {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: 1600px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.quil-quil {
  margin-right: 20px;
}
.quil-xianshi {
  width: 1200px;
  background-color: #ccc;
}
</style>
